.spHeader_breadCrumb{ display: block;  position: relative; margin-left: 12px; padding-left: 12px; margin-right: auto; }
.spHeader_breadCrumb ul{ display: flex; align-items: center; }

.spHeader_breadCrumb ul li{ padding-left: 24px; margin-left: 6px; background: url(../../assets/images/breadCrumb_arrow.svg) no-repeat left 1px; background-size: 20px auto; }
.spHeader_breadCrumb ul li:first-child{ padding-left: 0px; margin-left: 0px; background: none; }

.spHeader_breadCrumb ul li a{ color: #000; font-size: 15px; display: block; line-height: 1.4em; font-weight: 400; transition: all 0.3s ease; }

.spHeader_breadCrumb:before{ width: 1px; height: 14px; content: ""; position: absolute; left: 0px; top: 3px; background: #000000; }



