


@media only screen and (min-width: 1366px) and (max-width: 1500px){

    .tnpNetIn_summary h4{ font-size: 16px; }
    .tnpFuelRCA_summary h4{ font-size: 16px; }
    .tnpSolarRCA_summary h4{ font-size: 16px; }
    .tnpGRInfra_Issues h4{ font-size: 16px; }
    .tnpNetIn_inner_heading span{ font-size: 16px; }
    .tnpNetIn_inner_heading h2 { font-size: 36px;}

    .tnpNetIn_uses_heading small{ font-size: 18px; }
    .tnpNetIn_uses_heading h2 span { font-size: 42px !important;}
    .tnpNetIn_uses_heading h2 strong { font-size: 32px !important;}
    .tnpNetIn_chart_heading h2 { font-size: 16px !important; }

    .tnpNetIn_inner{ padding: 14px 16px !important; }

    .customTbl_headCell span{ font-size: 11px !important; }


    .rt_box_heading strong { font-size: 13px !important;}
    .mr_detail_content_heading h4 { font-size: 16px !important;}
    .rt_box_content strong{ font-size: 26px !important; }
    .rt_box_content span{ font-size: 14px; }
    .mr_detail_content_heading span { font-size: 14px !important;}
    .mr_detail_cr_data span{ font-size: 16px !important; }
    .mr_detail_cr_data .viewBtn { font-size: 12px !important; }

     
     
} 


@media only screen and (min-width: 0px) and (max-width: 1600px){
   .has_side_nav{ padding-left: 60px;}
    .right_content{ width:100% !important ;}
    body.menu-open .right_content{ width: calc(100% - 0px);}

    .comp_list ul li{ padding-left: 15px; padding-right: 15px;}
    .cs_pagination .cs_arrow svg{ width: 60px;}
}


















