 .pf_main_svg {
   display: block;
   width: calc(100% - 70px);
   overflow: visible !important;
 }

 .powerFlow_graph {
   position: relative;
   max-width: 512px;
   margin: auto;
 }

 .pf_heading {
   font-family: 'Quicksand', sans-serif;
   font-weight: 700;
   font-size: 18px;
   line-height: 1.4em;
   color: #000000;
 }



 .pf_graph_pos {
   position: absolute;
   left: 14px;
   top: 11px;
 }

 .pf_graph_pos_img {
   position: relative;
 }

 .pf_pos_battery_progress {
   position: absolute;
   left: 2px;
   bottom: 8px;
   transform: scale(0.90);
 }

 .pf_pos_battery_progress svg {
   display: block;
   width: 12px;
   height: 50px;
 }

 .pf_pos_battery_progress.pf_hide {
   visibility: hidden;
 }

 .pf_graph_pos_img img {
   display: block;
   transform: scale(0.90);
 }

 .pf_graph_data {
   position: absolute;
   left: 100%;
   top: 22%;
 }

 .pf_graph_data ul {
   position: relative;
   min-width: 120px;
   padding-top: 6px;
 }

 .pf_graph_data ul li {
   display: flex;
   width: 100%;
   padding-top: 2px;
   align-items: center;
 }

 .pf_graph_data ul li span {
   display: block;
   width: 50%;
   text-align: right;
   padding-right: 6px;
   font-weight: 400;
   font-size: 12px;
   line-height: 20px;
   color: #636466;
 }

 .pf_graph_data ul li strong {
   color: #000000;
   font-weight: 500;
   font-size: 14px;
 }

 .pf_graph_data ul li label {
   display: block;
   width: 50%;
   padding-left: 6px;
   font-weight: 400;
   font-size: 12px;
   line-height: 20px;
   color: #636466;
 }

 .pf_graph_data ul:after {
   content: '';
   width: 1px;
   margin-left: -1.5px;
   height: 100%;
   position: absolute;
   left: 50%;
   top: 0px;
   border-right: 1px solid #ADADAD;
 }

 .pf_graph_data ul:before {
   content: '';
   width: 51%;
   height: 1px;
   position: absolute;
   right: 50%;
   top: -1px;
   border-bottom: 1px solid #ADADAD;
 }


 .pf_graph_data.pf_graph_bottom {
   left: 90%;
   top: -33%;
 }

 .pf_graph_data.pf_graph_bottom ul {
   min-height: 70px;
 }

 .pf_graph_data.pf_graph_bottom ul:before {
   top: initial;
   bottom: 0px;
   max-width: 37%;
 }


 .pf_pos_grid .pf_graph_data.pf_graph_bottom ul {
   min-height: 80px;
 }

 .pf_pos_grid .pf_graph_data.pf_graph_bottom {
   left: 76%;
   top: 10%;
 }

 .pf_pos_battery .pf_graph_data.pf_graph_bottom {
   left: 78%;
 }


 .pf_graph_pos.pf_pos_generator {
   left: 2%;
   top: 64%;
 }

 .pf_pos_generator .pf_graph_data {
   left: 107%;
   top: 70%;
 }

 .pf_graph_pos.pf_pos_rectifier {
   left: 30%;
   top: 50%;
 }



 .pf_graph_pos.pf_pos_solar {
   left: 42%;
   top: 15%;
 }

 .pf_graph_pos.pf_pos_grid {
   left: initial;
   right: 15%;
   top: initial;
   bottom: 7%;
 }

 .pf_graph_pos.pf_pos_battery {
   left: initial;
   right: 20%;
   top: 30%;
 }


 .pf_battery_pointer {
   position: absolute;
   left: 18px;
   top: 25px;
 }

 .pf_battery_pointer svg {
   display: block;
 }



 .pf_circle.active {
   fill: transparent;
   stroke-dasharray: 3.2;
   stroke-dashoffset: 1000;

   filter: drop-shadow(0 0 2px #40A15D);
 }

 .pf_circle.pf_solar_circle.active {
   filter: drop-shadow(0 0 2px #FFC73C);
   animation: dash 20s linear infinite;
 }

 .pf_circle.pf_grid_circle.active {
   filter: drop-shadow(0 0 2px #9A76FA);
   animation: dash 20s linear infinite;
 }

 .pf_circle.pf_battery_circle.active {
   filter: drop-shadow(0 0 2px #5588FF);
   animation: dash 20s linear infinite;
 }

 .pf_circle.pf_rectifier_circle.active {
   filter: drop-shadow(0 0 2px #CAAA82);
   animation: dash 20s linear infinite;
 }

 .pf_circle.pf_gen_circle.active {
   animation: dash 20s linear infinite;
}

 .pf_circle.pf_tower_circle.active {
   filter: drop-shadow(0 0 2px #50D9FF);
   animation: dash 20s linear infinite;
 }


 /* .pf_solor_to_recti{ stroke-dasharray: 100;
  stroke-dashoffset: 400;
  animation: dash 5s linear infinite; }   */

 @keyframes dash {
   to {
     stroke-dashoffset: 0;
   }
 }



 .pf_path {
   opacity: 0;
   stroke: url(#pf_gradient) !important
 }

 .pf_path.pf_solor_to_recti.active {
   opacity: 1;
   stroke-dasharray: 50;
   stroke-dashoffset: 400;
   animation: dash 5s linear infinite;
 }

 .pf_path.pf_recti_to_tower.active {
   opacity: 1;
   stroke-dasharray: 50;
   stroke-dashoffset: -400;
   animation: dash 5s linear infinite;
 }

 .pf_path.pf_recti_to_battery.active {
   opacity: 1;
   stroke-dasharray: 50;
   stroke-dashoffset: -400;
   animation: dash 5s linear infinite;
 }

 .pf_path.pf_gen_to_recti.active {
   opacity: 1;
   stroke-dasharray: 50;
   stroke-dashoffset: 400;
   animation: dash 5s linear infinite;
 }

 .pf_path.pf_grid_to_recti.active {
   opacity: 1;
   stroke-dasharray: 50;
   stroke-dashoffset: -400;
   animation: dash 5s linear infinite;
 }

 .pf_path.pf_recti_to_battery.decharging {
   opacity: 1;
   stroke-dasharray: 50;
   stroke-dashoffset: 400;
   animation: dash 5s linear infinite;
   stroke: #D07272 !important;
 }


 .pf_battery_dischargin.decharging {
   color: red !important;
 }

 .pf_battery_dischargin.charging {
   color: rgba(50, 134, 75, 1) !important;
 }

 /* .pf_pos_battery_progress path{ opacity: 1; stroke-dasharray: 50; stroke-dashoffset: 400; animation: dash 5s linear infinite; } */



 .pf_pos_battery_progress path {
   transform: scaleY(0);
   transform-origin: center bottom;
   animation: draw 4s linear infinite;
 }

 @keyframes draw {
   to {
     transform: scaleY(1);
   }
 }



 .pf_pos_battery_progress:after {
   content: "";
   position: absolute;
   left: -2px;
   bottom: -1px;
   width: 0;
   height: 0;
   border: 9px solid transparent;
   border-bottom: 0;
   border-left: 18px solid white;
 }



 .pf_battery_pointer.active path {
   animation: col 1s linear infinite !important;
 }

 @keyframes col {

   0%,
   10% {
     fill: #A9A9A9
   }

   62% {
     fill: url(#pf_battery_active_gradient)
   }

   90%,
   100% {
     fill: #A9A9A9
   }
 }





 .pf_battery_bar {
   position: absolute;
   left: 3px;
   bottom: 12px;
   width: 11.34px;
   height: 44px;
 }

 .pf_battery_bar ul {
   display: flex;
   flex-wrap: wrap;
   align-items: flex-end;
   justify-content: flex-end;
   width: 12px;
   height: 40px;
 }

 .pf_battery_bar ul li {
   width: 100%;
   height: 5%;
   position: relative;
   transition: all 0.5s ease;
 }

 .pf_battery_bar ul li:before {
   content: "";
   position: absolute;
   width: 11.34px;
   height: 7.62px;
   display: block;
   background: url(../../assets/images/powerflow/batteryCell_green.svg) no-repeat center center;
   background-size: 100% 100% !important;
   left: 0px;
   top: 0px;
 }

 .pf_battery_bar.batteryCell_warning ul li:before {
   background: url(../../assets/images/powerflow/batteryCell_orange.svg) no-repeat center center;
 }

 .pf_battery_bar.batteryCell_down ul li:before {
   background: url(../../assets/images/powerflow/batteryCell_red.svg) no-repeat center center;
 }

 .pf_battery_bar.is_charging ul li:before {
   background: url(../../assets/images/powerflow/batteryCell_green.svg) no-repeat center center;
 }

 .pf_battery_bar ul li.batteryCell_disabled:before {
   background: url(../../assets/images/powerflow/batteryCell_disabled.svg) no-repeat center !important;
 }


 .pf_battery_bar.is_charging ul li.blink:before {
   background: url(../../assets/images/powerflow/batteryCell_green.svg) no-repeat center center !important;
 }

 .pf_battery_bar.is_discharging ul li.current {
   animation: cell_blink 0.8s linear infinite;
 }

 @keyframes cell_blink {

   0%,
   10% {
     opacity: 0;
   }

   41%,
   70% {
     opacity: 1;
   }

   90%,
   100% {
     opacity: 0;
   }
 }



 /* new powerflow design */

 .new_powerFlow_graph {
   width: 100%;
   position: relative;
 }

 .icon_label {
   width: 26px;
   height: 26px;
   border-radius: 50%;
   margin-right: 8px;
   display: flex;
   align-items: center;
   justify-content: center;
 }

 .icon_label img {
   display: block;
   margin: auto;
   max-width: 18px;
   max-height: 18px;
 }


 .npf_top_load{ position: relative; z-index: 2; width: 100%; display: flex; max-width: 80%; padding-bottom: 40px; justify-content: space-around; }


 
 .npf_graph_data {
  width: 100%; position: relative;
 }

 .npf_graph_data ul {
   position: relative;
   min-width: 110px;
   padding-top: 6px;
 }

 .npf_graph_data ul li {
   display: flex;
   width: 100%;
   padding-top: 2px;
   align-items: center; flex-direction: row-reverse;
 }

 .npf_graph_data ul li span {
   display: block;
   width: 50%;
   text-align: right;
   padding-left: 6px;
   font-weight: 500;
   font-size: 14px;
   line-height: 20px;
   color: #111315;
 }

 .npf_graph_data ul li strong {
   color: #111315;
   font-weight: 500;
   font-size: 16px;
 }

 .npf_graph_data ul li label {
   display: block;
   width: 50%;
   padding-right: 6px;
   font-weight: 500;
   font-size: 14px;
   line-height: 20px;
   color: #111315;
 }
 .npf_graph_data ul li small{ font-size: 70%;}

 .npf_gd_saprator {
   content: '';
   width: 1.2px;
   margin-left: -1.5px;
   height: 100%;
   position: absolute;
   left: 50%;
   top: 0px;
   background-color:#7243D0;
 }


 .npfChart_row{ width: 100%; display: flex; align-items: flex-end; justify-content: space-between; padding-top: 19%; }
 
 .npfChart_cell{ width: 16.66%;  position: relative; padding: 0px 10px; text-align: center; }

.npf_graph_pos{ position: relative;   width: 130px; margin: auto; }

.npf_graph_pos.pf_pos_grid{ max-width: 180px;}

.npf_cricle{ width: 100%;}

.npf_cricle svg{ display: block; max-width: none; width: 100%; height: auto;}

.npf_graph_pos_img{ position: absolute; left: 50%; transform: translateX(-50%);  bottom: 13px; width: 72%; }

 .npf_graph_pos_img img{ display: block; max-width: none; width: 100%; height: auto; }



.npf_label{ color: #000; text-align: center; font-size: 0.9em;  font-weight: 500;  line-height: 1.2em; display: block; border-radius: 4px;  text-transform: uppercase; background-color: #B798F5; padding: 2px 6px; max-width: 140px; margin: auto;  margin-top: 15px; }


.npf_label.npf_label_idle{ background-color: #86C493;}

.npf_label.npf_label_battery{ background-color: #86B0FF;}
.npf_label.npf_label_solar{ background-color: #FEDE81;}
.npf_label.npf_label_rectifier{ background-color: #C7B394;}
.npf_label.npf_label_tower{ background-color: #96D5E7;}


.npfChart_flow_content{ position: relative; margin-top: -100px;}
.npfChart_barsRow{ position: absolute; left: 8%; bottom: 90px; width: calc(67.68% + 40px); }

.npfChart_bars_svg{ width: 100%; height: auto; display: block; position: absolute; left: 0px; bottom: 26px;}


.svg_gen_to_recti{ left: 24%; width:74%;  }

.svg_battery_to_recti{ left: 45%; width:51%;  bottom: 42px;  }

.svg_solar_to_recti{ left: 71%; width:23%;  bottom: 2px;  }

.svg_recti_to_tower{ left: 100%; width: 16.66%; bottom: -20px; }


.svg_grid_to_recti .pf_path{ stroke: #6A54A3 !important; }

.svg_gen_to_recti .pf_path{ stroke: #32864B !important; }

.svg_battery_to_recti .pf_path{ stroke: #0058FF !important; }

.svg_battery_to_recti .pf_path{ stroke: #0058FF !important; }

.svg_solar_to_recti .pf_path{ stroke: #F0B41D !important; }
.svg_recti_to_tower .pf_path{ stroke: #6A54A3 !important; }

.svg_recti_to_tower .pf_path.pf_recti_to_tower.active, .svg_grid_to_recti .pf_path.pf_grid_to_recti.active{ stroke-dashoffset: 400; }
 


.npfChart_flow_content .pf_battery_bar {
  position: absolute;
  left: 0px;
  bottom: 21px;
  width: 19.59px;
  height: 83px;
}
.npfChart_flow_content .pf_battery_bar ul{  width: 19.59px;
  height: 78px; }


.npfChart_flow_content .pf_battery_bar ul li:before{ width: 19.59px; height: 11.13px;}

.npfChart_flow_content .pf_battery_bar ul li:before {
  width: 22.59px;
  height: 14.13px;
}


.npf_graph_pos_img .pf_battery_pointer{ left: 34px; top: 46px; }

.npfCell_tower .npf_graph_pos_img{ transform: translateX(-38%);  bottom: 8px;  width: 100%; }

.npfCell_rectifier .npf_graph_pos_img{ left: 54%; bottom: 6px; width: 69%; }

.npfChart_barsRow .svg_gen_to_recti{ bottom: 8px; }

.npfChart_barsRow .svg_battery_to_recti{ bottom: 33px; }