
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

ol,
ul,
li {
  list-style: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
strong,
b {
  font-weight: normal; margin: 0px;
}

em {
  font-style: normal;
}

input,
textarea,
select {
  border: 0px none;
  border-radius: 0px;
  background: none;
  outline: none;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  font-family: 'Roboto', sans-serif;
  ;
}

img {
  display: block;
  height: auto;
  outline: none;
}

input[type='submit'] {
  border-radius: 0px;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
}

textarea {
  resize: none;
  overflow: auto;
}

:focus {
  outline: none;
}

.clearfix:after {
  content: "";
  display: block;
  clear: both;
  height: 0px;
  visibility: hidden;
}

.clearfix {
  display: block;
  zoom: 1;
}

i,
em {
  font-style: normal;
}

html {
  min-height: 100%;
}

dl, ol, ul{ margin: 0px; padding: 0px;}

body {
  width: 100%; font-size: 16px;
  font-family: 'Roboto', sans-serif; font-weight: 400; line-height: 1.5em;
}

.autoContent {
  padding: 0px 20px;
  max-width: 1366px;
  width: 100%;
  margin: auto;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.contentWrap{ width: 100%; padding: 0px 20px;}


.onoffGridSites_header{ width: 100%; border-bottom:1px solid #EBEBEB; }

.onoffGridSites_grid{ width: 100%; display: flex; }

.onoffGridSites_box{ width: 100%; padding: 10px 15px; border-left: 1px solid #D1D1D1;  margin-bottom: -1px; }
.onoffGridSites_box:first-child{ border-left: 0px none;}

.onoffGridSites_box strong{ padding-left: 24px; position: relative; color: #fff; display: block; font-weight: 500; font-size: 13px; line-height: 1.4em; padding-bottom: 8px; }

.onoffGridSites_icon{ width: 20px; height: 16px; display: flex; align-items: center; justify-content: center; position: absolute; left: 0px; top: 0px; }

.onoffGridSites_icon svg{ display: block; max-width: 16px; max-height: 16px; }

.onoffGridSites_box ul{ display: flex; }
.onoffGridSites_box ul li{ width: 100%; padding: 0px 6px; text-align: center;  border-left: 1px solid #fff; }
.onoffGridSites_box ul li:first-child{ border-left: 0px none;}

.onoffGridSites_box ul li h4{ color: #fff; font-weight: 700; font-size: 18px; line-height: 1.4em; }

.onoffGridSites_box ul li span{ color: #fff; display: block; font-weight: 500; font-size: 11px; line-height: 1.4em; }

.applyFilter_row{ width: 100%; background: #FBFBFB;  border-bottom: 1px solid #EBEBEB; }

.applyFilter_inner{ width: 100%; padding: 10px 0px; display: flex; align-items: center; justify-content: center; }
.applyFilter_inner > ul{ display: flex; justify-content: center; align-items: center; width: 100%; padding-right: 20px; }

.applyFilter_inner > ul > li{ border-left:1px solid #D1D1D1; width: 100%; }

.applyFilter_inner > ul > li:first-child, .applyFilter_inner > ul > li:last-child{ border-left: 0px none;}

.applyFilter_box{ width: 100%; padding: 0px 10px;  text-align: center;} 

.applyFilter_box select{ display: inline-block; padding-right: 15px; background: url(./assets/images/dropdown_arrow.svg) no-repeat right center; background-size: 10px auto; text-align: center; color: #33383F;  font-size: 11px; font-weight: 500;}

.applyFilter_box select option{ padding: 6px; font-size: 14px; }


.applyFilter_button{ background: #000; border: 1px solid #000;  border-radius: 2px !important; font-weight: 500;  font-size: 14px; line-height: 18px; text-align: center; color: #FFFFFF; padding: 5px 15px; cursor: pointer; transition: all 0.3s ease; display: block; width: 100%; white-space: nowrap; }

.headerMain_export_box .dropdown-menu{  border-radius: 0px;  border: 1px solid #8C8C8C; }


.applyFilter_button:hover, .applyFilter_button.active{ background-color: #545454; border-color: #545454;}

.applyFilter_button.disabled{ background-color: #BEBEBE; border-color: #BEBEBE; pointer-events: none; }


/* export dropdown stle */


.headerMain_export_box{ padding-left: 15px;}

.headerMain_export_box .btn-primary{ background: #FCFCFC; border-color: #FCFCFC;   border-radius: 5px; font-weight: 500; font-size: 12px;  line-height: 15px; color: #000000; display: flex; gap: 10px; align-items: center; justify-content: center; padding: 10px 15px !important; }

.headerMain_export_box .btn-primary i{ display: block; width: 12px; height: 12px; background: url(./assets/images/export_icon.svg) no-repeat center center; background-size: auto 100%; }

.headerMain_export_box .btn-primary:after{ border: 0px none !important; display: block; width: 12px; height: 12px; background: url(./assets/images/dropdown_arrow.svg) no-repeat center center; background-size:8px auto ; }

.headerMain_export_box .btn-primary:active, .headerMain_export_box .btn-primary:hover {  background: #F7F7F7; border-color: #F7F7F7; color: #000000; }

.chart_containter{ padding: 20px 0px;}

.box_card{ background: #FFFFFF; box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1); border-radius: 5px; padding: 10px 15px; }

.box_card_inner{ width: 100%;}

.box_card .infrastructureChartBox .actualYtdHrsBox{ position: relative; z-index: 2; float: right;  margin-bottom: -36px;  flex-wrap: wrap; max-width: 38%; }


.marker_group{ width: 32px; height: 32px; border-radius: 50%; background:url(./assets/images/marker_group.svg) no-repeat center center; background-size: 100% auto; display: block;}

.chartTitleHeading{ font-weight: 500; padding-top: 4px; padding-bottom: 10px; font-size: 16px;  line-height: 1.2em; color: #000000; }

.heatmap_out .chartTitleHeading{ padding-bottom: 0px; }


.cs_traiff_title{ width: 100%; display: flex; align-items: center; padding-bottom: 10px; }
.cs_traiff_title span{ width: 32px; height: 32px; height: auto; display: block; flex-shrink: 0; margin-right: 10px; }
.cs_traiff_title img{ display: block; width: 100%; height: auto; max-width: none;}

.cs_traiff_title h3{ padding: 0px !important;}

.cs_traiff_row{ width: 100%; display: flex; flex-wrap: wrap;}

.cs_traiff_data{ width: 40%; padding-right: 40px; padding-bottom: 20px; } 

.cs_traiff_chart{ width: 60%;}

.cs_traiff_data h2{ padding-top: 20px; font-weight: 900; font-size: 54px;  line-height: 1em;  text-align: right;  color: #000000; }
.cs_traiff_data h2 small{ display: block; font-size: 20px; font-weight: 700; line-height: 22px; }


.cs_traiff_data ul{ padding-top: 40px;}

.cs_traiff_data ul li{ width: 100%; padding-bottom: 16px; display: flex; align-items: center; justify-content: space-between;}
.cs_traiff_data ul li:last-child{ padding-bottom: 0px;}

.cs_traiff_data ul li span{ font-weight: 400; font-size: 12px;  line-height: 1.2em;    color: #000000; }

.tarrif_status{ font-weight: 600; font-size: 22px;  line-height: 1.2em; color: #000000; padding-left: 24px; background: url(./assets/images/tarrif_green_down.svg) no-repeat left center;background-size: 13px auto !important; }

.tarrif_status.red_up{ background: url(./assets/images/tarrif_red_up.svg) no-repeat left center; }
.tarrif_status.red_down{ background: url(./assets/images/tarrif_red_down.svg) no-repeat left center; }
.tarrif_status.green_up{ background: url(./assets/images/tarrif_green_up.svg) no-repeat left center; }

.amp_btn_margin .ampButtonsGroup{ margin-bottom: -32px; position: relative; z-index: 2; }

.exe_summary_header .ampButtonsGroup{ margin-top: -50px; }



.solarPotential_lossBox .fuelAnomalies_list{ min-height: 150px; }

.pws_chart{ position: relative; z-index: 1;}

.pws_chart div, .pws_chart svg{ overflow: visible !important;}

.pws_box{ width: 100%;}

.pws_head{ width: 100%; display: flex; justify-content: space-between;}

.sitePerf_right .pws_head{ align-items: center; }

.pws_head_title{ position: relative; display: flex; align-items: center; padding-bottom: 16px; }

.pws_head_title i{ width: 36px; height: 36px; display: flex; justify-content: center; align-items: center; background-color: #7243D0; border-radius: 50%; flex-shrink: 0; }

.pws_head_title i img, .pws_head_title i svg{ display: block; max-height: 24px; max-width: 24px; }

.pws_head_title span{ font-weight: 500; font-size: 18px; line-height: 1.2em; color: #000; padding: 3px 0px 0px 12px; display: block; }


.pws_values{ width: 100%; padding-bottom: 10px; }

.pws_values ul{ display: flex; align-items: center; }

.pws_values ul li{ width: 50%; padding: 0px 10px 0px 10px; position: relative; }

.pws_values ul li:before{ content: ""; width: 1px; height: 70px; background-color: #ADADAD; position: absolute; left: 0px; top: 50%; transform: translateY(-50%); }
.pws_values ul li:first-child::before{ display: none; }

.pw_values_box{ display: inline-block; vertical-align: top;}

.pw_values_box small{ font-size: 18px; line-height: 1.2em; color: #000000; display: block;padding-bottom: 5px; }

.pw_values_box h2{ font-size: 44px; line-height: 1em; color: #000; font-weight: 900; }

.pws_has_arrow{  padding-left: 25px; }

.pws_has_arrow h2{ margin-left: -25px;  padding-left: 25px;  background: url(./assets/images/tarrif_green_up.svg) no-repeat left 20px; background-size: 16px auto !important;  }

.pw_values_box.pws_red h2{ background: url(./assets/images/tarrif_red_up.svg) no-repeat left 20px; }

.pw_values_box.pws_grey h2{ background: url(./assets/images/tarrif_grey_up.svg) no-repeat left 20px; }




.pw_values_box h2 span{ display: block; position: relative;}

.pw_values_box h2 sub{ font-size: 18px; line-height: 1em;  font-weight: 600;   position: relative; bottom: 6px; display: inline-block; vertical-align: bottom; }

.pw_values_box h2 i{ font-size: 20px; line-height: 1em; color: #01A340; display: block; text-align: right; font-weight: 400; }
 

.pw_values_box.pws_red i{ color: #D70000; }

.pw_values_box.pws_grey i{ color: #D0D0D0; }

.pws_head_btn span{ padding: 6px; border-radius: 10px; font-size: 16px; font-weight: 500; }

.pws_disabled .pw_values_box h2{  color: #D0D0D0; }
.pws_disabled svg g path:first-child{  fill: #D0D0D0 !important; }
.pws_disabled svg g path:nth-child(2){  fill: #E2E5D1 !important; }


.dropdown-item.active, .dropdown-item:active {
  color: #fff !important;
  text-decoration: none;
  background-color:#000000 !important;
}


.exdb_summary_header{ border-bottom: 1px solid #FFC400;}
.exdb_summary_header .headerMain_inner{ align-items: end;}

.minimize_summary_button{ display: block; text-align: center; position: relative; background-color: #19AAF8; border-radius: 20px 20px 0px 0px; margin-bottom: -10px; }

.minimize_summary_button span{ display: block; padding:10px 40px 8px 50px; color: #fff; position: relative;  background: url(./assets/images/summary_up_arrow1.svg) no-repeat right 8px center; background-size: 28px auto !important; min-width: 160px; transition: all 0.3s ease; }

.minimize_summary_button.btn_minimize span{ background: url(./assets/images/summary_down_arrow1.svg) no-repeat right 8px center; }
 
.minimize_summary_button span:before{ content: ''; width: 50px; height: 100%; display: block; position: absolute; left: 0px; top: 0px; background: url(./assets/images/summary_logo.svg) no-repeat center center; background-size: 28px auto; opacity: 0; }

.minimize_summary_button.btn_minimize span:before{ opacity: 1;}


.ts_content{ padding-top: 10px;}

.ts_headelines{ width: 100%; display: flex; justify-content: space-between; align-items: center; padding-bottom: 12px; flex-wrap: wrap; }

.ts_headelines h2{ font-weight: 600;  font-size: 20px; line-height: 1.2em; color: #000; margin-right: auto; }

.ts_top_status{ margin-left: auto;}
.ts_top_status ul{ display: flex; }
.ts_top_status ul li{ padding-left: 20px; padding-bottom: 4px;}
.ts_top_status ul li h4{ position: relative; font-weight: 600; font-size: 18px; line-height: 1.2em;  color: #000; padding-left: 20px; }
.ts_top_status ul li h4 small{ font-weight: 400;}


.ts_top_status_date{ text-align: right; font-weight: 500; font-size: 11px;  line-height: 1.5em;  color: #33383F; }

.exdb_candlestick_chart{ width: 100%; }

 .psu_headlines_row{  width: 100%; display: flex; align-items: center; justify-content: space-between;  padding-bottom: 12px; margin-bottom: 10px; border-bottom: 1px solid #8C8C8C; }

 .psu_selectbox {
  display: inline-block;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  background: #fff;
  border-radius: 5px;
 
  border: 1px solid #EFEFEF;
 
}

.psu_selectbox select{  padding: 8px 20px 8px 10px;  background: url(./assets/images/dropdown_arrow.svg) no-repeat right 5px center;
  background-size: 10px auto; }

.has_info_icon{ position: relative;}

.box_info{ width: 24px; height: 24px; position: absolute; right: 10px; top: 10px;}

.box_info_icon{ width: 24px; height: 24px; display: block;  background: url(./assets/images/info_icon.svg) no-repeat center center;
  background-size: 90% 90%; cursor: pointer;   }

  .color-green {
    color: #01A340;
}

.color-red {
    color: #F13534;
}
.color-blue {
  color: #187CF5;
}


.bg-green{ background-color: #01A340 !important;}
.bg-yellow{background-color: #E5CF1A !important;}
.bg-orange{background-color: #F88800 !important;}
.bg-red{background-color: #F13534 !important;}
.bg-blue{background-color: #187CF5 !important;}
.bg-blue2{background-color: #2B999F !important;}



.mvc_card_detail_cell .raised_text{ color: #000;  font-size: 14px;  font-weight: 600; } 
.mvc_card_detail_cell strong{ color: #000;  font-size: 16px;  font-weight: 600;  } 

.label_btn{ color: #01A340; border-radius: 40px; background: #BFE8CF; padding: 4px 15px; font-size: 16px;
 font-weight: 700; }

 .mvc_card_detail_cell .raised_circle{ top: 3px;}

.mvc_card_detail_row, .mvc_latlong_row{  padding-bottom: 8px; margin-bottom: 8px; border-bottom: 1px solid #EFEFEF; }


.mvc_status{ width: 30px; height: 30px; border-radius: 50%; background-color: #01A340 !important; background: url(./assets/images/mvc_status1.svg) no-repeat center center; background-size: 22px auto !important;  }

.mvc_status_dark{ background-color: #32323E !important; background: url(./assets/images/mvc_status2.svg) no-repeat center center; }

.mvc_status_blue{ background-color: #838DFD !important; background: url(./assets/images/mvc_status3.svg) no-repeat center center; }

.mvc_latlong_cell{ display: flex; align-items: center; padding-left: 6px;}
.mvc_latlong_cell:first-child{ padding-left: 0px;}

.mvc_latlong_cell span{ color: #000; font-size: 14px;  font-weight: 400; padding-right: 10px; }

.mvc_latlong_cell strong{ color: #000;  font-size: 14px;  font-weight: 600; padding-right: 10px; }

.mvc_latlong_cell li{ padding-left: 6px;}

.mvc_performance{ width: 100%; display: flex; align-items: center; justify-content: space-between; background: #F7F7F9; padding: 28px 12px 10px; margin-bottom: 10px}

.mvc_performance strong{ font-size: 14px; line-height: 1.3em; color: #000; font-weight: 500; display: block; padding-right: 15px; }

.mvc_performance ul{ display: flex; align-items: center; }

.mvc_performance ul li{ padding: 0px 10px; border-left:1px solid #EFEFEF; }
.mvc_performance ul li:first-child{ border-left:0; padding-left: 0px;}

.mvc_performance .raised_text{ font-size: 14px; color: #000; }
.mvc_performance .raised_text .raised_circle{ top: 3px; }

.mvc_site_list{ width: 100%; padding-top: 10px; border-top: 1px solid #EFEFEF; }

.mvc_site_list ul{ max-height: calc(100vh - 490px); overflow-y: auto; min-height: 150px; }

.mvc_site_list ul li{ padding:5px 20px; background: #fff; border-bottom: 1px solid #EFEFEF; }
.mvc_site_list ul li:nth-child(even){ background: #F7F7F9; }

.mvc_site_list ul li a{ display: flex; align-items: center; justify-content: space-between; }

.mvc_site_list ul li a span{ font-size: 14px; line-height: 1.3em; color: #000; display: block; flex-shrink: 0; }

.mvc_site_list ul li a b{ width: 100%; text-align: right;  font-size: 14px; line-height: 1.3em; font-weight: 600; color: #000; text-transform: uppercase; }

.mvc_visit_btn{ padding-top: 10px; }

.mvc_visit_btn .applyFilter_button{ padding:15px 20px; }

.mvc_map_view .mapchart_box{ height: calc(100vh - 270px) !important; overflow-y: auto; min-height: 150px;  }

.has_side_nav{ width: 100%; display: flex; align-items: flex-start; }

.wrapper{ width: 100%;}

.MapView_wrapper{ width: 100%;}

.exdb_summary_main{ width: 100%;}


.has_side_nav .right_content{ width: calc(100% - 60px);}
body.menu-open .has_side_nav .right_content{ width: calc(100% - 270px);}

 
.customTbl_headCell{ position: relative; display: inline-flex; padding-right:22px; }

.filter_header_btn{ position: absolute !important; right: 0px; padding: 5px; top: -1px; z-index: 333; cursor: pointer; width: 20px; }

.filter_header_btn svg{ display: block; width: 100%; height: auto;}

.siteFueling_table_main .MuiDataGrid-menuIcon{ display: none !important;}

.btn{ font-size: 16px;  font-weight: 500; line-height: 15px; padding:10px 15px ;  border-radius: 0px !important; }

.btn.btn-dark{ background-color: #000; border-color: #000; color: #fff;   }

.btn.btn-secondary{ background-color: #F7F7F7; border-color: #F7F7F7; color: #131325;   }

.btn.btn-outline-secondary{ border-radius: 0px !important; font-size: 16px;  font-weight: 600; line-height: 15px; padding:10px 15px ;   }

.btn.bg_sky_blue{ background-color: var(--color_sky_blue); border-color: var(--color_sky_blue);  }

.stf_addbtn svg{ max-width: 20px;}
.stf_tableWrap{  font-family: 'Roboto', sans-serif !important; }
.stf_tableWrap thead{ background-color: #000 !important; color: #fff !important;}

.stf_tableWrap thead th, .stf_tableWrap tbody td{ padding:12px 12px 12px 15px;   font-family: 'Roboto', sans-serif !important;   font-size: 12px;  font-style: normal;  font-weight: 500; line-height: 19px; border-left: 1px solid #D8D8D8; border-bottom-color: #D8D8D8; }

.stf_tableWrap thead th:first-child, .stf_tableWrap tbody td:first-child{ border-left: 0px none;}

.stf_tableWrap table{  border: 1px solid #D8D8D8; }

.customTbl_headCell span{ color: #FFF !important;  font-size: 12px; font-weight: 500; line-height: 19px; align-items: flex-start; }
.customTbl_headCell span svg{ margin-left: 10px !important; opacity: 0.5 !important; color: #FFF !important;  }
.customTbl_headCell span.Mui-active{ color: #FFF ; }

.customTbl_headCell span.Mui-active svg{ opacity: 1 !important; color: #FFF ; }


.stf_tableWrap .MuiTableHead-root{ position: sticky; top: 0px;}
.stf_tableWrap .MuiTableContainer-root{ overflow-y: auto;  height: calc(100vh - 242px); }
/* 
.tniSolarRCATable .stf_tableWrap .MuiTableContainer-root{ height: calc(100vh - 365px); }

.tniGridRcaTable .stf_tableWrap .MuiTableContainer-root{ height: calc(100vh - 365px); }

.tniFuelRCATable .stf_tableWrap .MuiTableContainer-root{ height: calc(100vh - 365px); }

.tniNetworkInsightsTable .stf_tableWrap .MuiTableContainer-root{ height: calc(100vh - 326px); } */

.stf_filter_header{ padding: 8px 12px 8px 12px; }

.stf_filter_title{ color: #131325; display: block; font-weight: 500; font-size: 13px; line-height: 1.4em; padding: 0px 0px 8px 0px; display: flex; justify-content: space-between; align-items: center; }

.stf_filter_header input{ width: 100%; box-shadow: 0px 0px 0px transparent !important; font-size: 12px; line-height: 20px;   color: #1A1D1F;  background: url(./assets/images/search_icon.svg) no-repeat left 6px center !important; background-size: 16px auto !important; background-color: #fff !important; padding-left: 30px !important; }

.stf_filter_dropdown .MuiButtonBase-root{ padding: 0px 10px 0px 0px !important; margin-top: -2px; }

.stf_filter_dropdown ul li > label{ color: #131325; display: block; font-weight: 500; font-size: 13px; line-height: 1.4em;  }


.stf_filter_footer{ width: 100%; padding: 0px 12px 12px 12px; display: flex; gap: 10px; }


.stf_filter_dropdown .MuiPaper-root{
  border-radius: 0px !important;
  border: 1px solid #8C8C8C !important; box-shadow: 0px 0px 0px transparent !important;
}


/* width */
 ::-webkit-scrollbar {
  width: 6px;  height: 6px; border-radius: 3px;
}

/* Track */
  ::-webkit-scrollbar-track {
  background: #F7F7F7; border-radius: 3px; 
}

/* Handle */
 ::-webkit-scrollbar-thumb {
  background: #8C8C8C; border-radius: 3px;
}

body{ overflow-x: hidden;}

.sites_summaryBtn{ display: block; text-align: center; position: relative; background: #000;  color: #FFC400; border-radius: 20px 20px 0px 0px; margin-bottom: -10px; }
.sites_summaryBtn span{ display: block; padding:10px 40px 8px 10px; font-weight: 600; font-size: 15px; text-transform: uppercase; position: relative;  background: url(./assets/images/summary_up_arrow.svg) no-repeat right 8px center; background-size: 28px auto !important; min-width: 160px;   }

.sites_summaryBtn.btn_minimize{ background: linear-gradient(90deg, #FF7F02 0%, #FFC400 100%);  color: #000; }

.sites_summaryBtn.btn_minimize span{ background: url(./assets/images/summary_down_arrow.svg) no-repeat right 8px center; padding-left: 50px; }

.sites_summaryBtn.summaryBtn_notIcon span{ padding-left: 10px;}
 
.sites_summaryBtn .summaryIcon{ content: ''; width: 50px; height: 100%; display: flex; align-items: center; justify-content: center; position: absolute; left: 0px; top: 0px; opacity: 0; }

.sites_summaryBtn .summaryIcon svg{ display: block; height: 22px; max-width: 40px; width: auto; }

.sites_summaryBtn.btn_minimize .summaryIcon{ opacity: 1;}


.summaryEdge{ width: 30px; height: 100%; position: absolute; left: -30px; bottom: 0px;  background: #000; }

.summaryEdge:before{ width: 100%; height: 100%; background-color: #fff; content: ""; position: absolute; left: 0px; top: 0px; border-radius: 0px 0px 14px 0px; }

.summaryEdge.symmaryEdgeRight{ left: initial; right: -30px;  background: #000;  }
.summaryEdge.symmaryEdgeRight:before{ border-radius: 0px 0px 0px 14px ;}

.sites_summaryBtn.btn_minimize .summaryEdge{  background: linear-gradient(90deg, #FF7F02 0%, #FF7F02 100%); }
.sites_summaryBtn.btn_minimize .summaryEdge.symmaryEdgeRight{  background: linear-gradient(90deg, #FFC400 0%, #FFC400 100%); }



.sitesHeader_dropdown{ margin-right: 30px; width: 180px; margin-top: -10px; margin-bottom: -10px;  }

.sitesHeader_dropdown .MuiAutocomplete-clearIndicator{ display: none !important; }

.sitesHeader_dropdown  .MuiInputBase-colorPrimary{ background-color: #F2F2F2 !important;  padding-right: 26px !important;  border-radius: 0px !important;     min-height: 60px; }


.sitesHeader_dropdown .MuiOutlinedInput-notchedOutline{ border: 0px none !important; border-radius: 0px !important;  }

.sitesHeader_dropdown .MuiOutlinedInput-input{ color: #33383F;  font-size: 14px;  font-weight: 500; }




