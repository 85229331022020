
.actualYtdHrsBox{background: #F1F1F1; display: inline-flex; padding: 10px 12px 8px 12px; font-weight: 400; font-size: 14px; line-height: 20px; color: #000000; gap: 8px; align-items: center; border-radius: 2px; }

.actualYtdHrsBox p{ font-weight: 500; }
.actualYtdHrsBox strong{ font-weight: 900; }

.actualYtdHrsBox span{ display: block; font-weight: 400; color: #D70000; padding-left: 24px; border-left: 1px solid #8C8C8C; line-height: 16px; background: url(../../assets/images/arrowUpRed.svg) no-repeat left 10px center; background-size: auto 12px;  padding-top: 2px; margin-top: -1px; }  



















