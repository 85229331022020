.tvg_topTabs_main{ width: 100%;}
.tvg_topTabs_row{ width: 100%;}

.tvg_topTabs_ul{ width: 100%; display: flex; gap: 10px; padding-bottom: 6px;}

.tvg_topTabs_li{ width: 16.66%;}


.tvg_topTabs_box{ width: 100%; min-height: 50px; background: #F7F7F7; border-bottom: 3px solid #F7F7F7; color: #131325;  border-radius: 2px; padding:6px 10px 4px 10px; display: flex; justify-content: space-between; align-items: center; cursor: pointer; transition: all 0.175s ease; }



.tvg_topTabs_icon{ width: 24px; flex-shrink: 0; margin-right: 6px;}

.tvg_topTabs_icon svg{ display: block; max-height: 20px; max-width: 20px; transition: all 0.175s ease;}

.tvg_topTabs_box p{ width: 100%; font-weight: 500; font-size: 12px; line-height: 1.4em; transition: all 0.175s ease;  }
.tvg_topTabs_price{ text-align: right; padding-left: 4px; color: #6F767E; transition: all 0.175s ease; }
.tvg_topTabs_price h3{ position: relative; font-weight: 600; font-size: 20px; line-height: 1.2em; text-align: right;  }

.tvg_topTabs_price span{ font-weight: 600; display: block; font-size: 10px; line-height: 1.2em; text-align: right;   }

.tvg_topTabs_li.active .tvg_topTabs_box{ background-color: #000000 !important; border-bottom-color: #FFC400; color: #fff; }

.tvg_topTabs_li:hover .tvg_topTabs_box{ background-color: #ececec; border-color: #ececec;}

.tvg_topTabs_li.active .tvg_topTabs_price{ color: #fff;}

.tvg_topTabs_li.active svg, .tvg_topTabs_li.active svg path{   stroke: #fff !important;}


.tvg_toptab_more .btn-primary{ background: none !important; padding: 0px !important; border: 0px none !important; display: block; width: 100%; text-align: left;}

.tvg_toptab_more .btn-primary:after{ display: none;}

.tvg_toptab_more .tvg_topTabs_li{ width: 100%; background: none !important; padding: 0px !important; border: 0px none !important; display: block; width: 100%; text-align: left;}


.tvg_toptab_more .dropdown-menu{ min-width: 300px; border-radius: 0px;  border: 1px solid #8C8C8C; }

.tvg_toptab_more .dropdown-menu .tvg_topTabs_box{ background-color: #fff; border-radius: 0px; border-bottom: 1px solid #8C8C8C; }


.tvg_toptab_more .tvg_topTabs_li:last-child .tvg_topTabs_box{ border-bottom: 0px none !important;}

.tvg_toptab_more .tvg_topTabs_li:hover .tvg_topTabs_box{ background-color: #ececec; }

.tvg_price_border{ padding-right:4px; }
.tvg_price_border h3::after{ content: ""; height: 14px; position:absolute; right: -5px; top: 5px; border-right: 1px solid #6F767E; }








