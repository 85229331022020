.side_nav{ width: 70px; background-color: #131325; color: #fff; height: 100vh; overflow-x: hidden; overflow-y: auto; flex-shrink: 0; position: sticky; left: 0px; top: 0px; z-index: 9999; }




.sn_logo_row{ width: 100%; padding:26px 10px 15px 10px; display: flex; justify-content: space-between; align-items: center; }

.sn_logo_row span{ cursor: pointer; max-width: 150px; display: block;}
.sn_logo_row img{ display: block; margin: auto; width: 100%; height: auto;}

.sn_menu_icon{ width: 26px; height: 24px; display: block; flex-shrink: 0; cursor: pointer; margin-left: auto; }

.menu_close_img{ width: 22px; display: block; height: auto; margin: auto;}

.menu_bar_img{ width: 100%; display: block; height: auto; margin: auto;}


.sn_logo{ display: none;}


.menu{ width: 100%; height: calc(100vh - 184px); overflow-x: hidden;  overflow-y: auto; -webkit-overflow-scrolling: touch; scrollbar-width: none;  }

.menu::-webkit-scrollbar, .side_nav::-webkit-scrollbar {
    display: none;
}

.menu ul{ padding: 10px; }
 
.nav_link{ height: 100%; padding: 8px 8px;  display: flex; align-items: center;  align-items: center; border-radius: 10px; position: relative;  color: #FFF; border-radius: 10px; }



.nav_link i{ border-radius: 10.284px; display: block; width: 36px; height: 24px; display: flex; align-items: center; justify-content: flex-start;  }

.nav_link i svg{ display: block; max-width: 22px; max-height: 22px; }
.nav_link i svg path{  stroke: #6F767E;  }
 


.nav_link strong{ display: block; padding-left:3px;  font-size:14px; font-weight:300; line-height: 1.3em; letter-spacing: -0.2px; }

.menu_ul li{ padding-bottom: 10px;}

.nav_link span{ display: block;  font-size: 14px; font-weight:500;  line-height: 1.4em;}

.menu_ul li a.active, .menu_ul li a:hover{ background: #262639;}
.menu_ul li a.active strong, .menu_ul li a:hover strong{ background: linear-gradient(25deg, #FF7F02 0%, #FFC400 100%); background-clip: text; -webkit-background-clip: text; -webkit-text-fill-color: transparent; font-weight:700; }

.menu_ul li a.active svg path, .menu_ul li a:hover svg path{ stroke: url(#gradient) #FF7F02;  }


.menu_ul li.disabled{ pointer-events: none;}
.menu_ul li.disabled .nav_link{ background-color: #3C3C56; color: #5E5E79; }
.menu_ul li.disabled .nav_link i{ background: #5E5E79; }
.menu_ul li.disabled .nav_link i svg{ opacity: 0.5;}



.side_nav.menu_hide{ width: 60px; }
.side_nav.menu_show{ width: 270px; }
.side_nav.menu_hide .nav_link strong{ display: none;}

.side_nav.menu_hide .sn_menu_icon{ margin: auto;}

.nav_overlay{ width: 100%; height: 100%; position: fixed;left: 0px; top: 0px; background-color: rgba(0, 0, 0, 0.3); z-index: 9998; display: none; }


.sn_footer{ width: 100%; padding: 10px;}

.help{ width: 100%; border-top: 1px solid #fff; padding-top: 20px; text-align: center; padding-bottom: 12px; }

.help_icon_row{ width: 100%; display: flex; align-items: center; justify-content: flex-start; padding-left: 8px; }

.help_icon{ width: 22px; display: block;}
.help_icon img{ width: 100%; display: block;} 

.help_text{ font-size: 13px;  font-weight: 600;  line-height: 22px; letter-spacing: -0.15px; color: #6F767E; padding-left: 12px; }



 
.theme_switch{ width: 100%; background-color: #F4F4F4; border-radius: 40px; padding: 4px; display: flex; gap: 4px; cursor: pointer; align-items: center; justify-content: center; position: relative; }

.theme_switch:after{ width: calc(50% - 8px); height: calc(100% - 8px); content: ""; background-color: #FCFCFC; border-radius: 40px; position: absolute; left: 4px; top: 4px; box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), 0px 2px 0px 0px rgba(255, 255, 255, 0.25) inset, 0px -1px 1px 0px rgba(0, 0, 0, 0.04) inset; transition: all 0.3s ease; }

 

.theme_switch.is_light:after{ left:4px; }
.theme_switch.is_dark:after{ left: calc(50% + 4px); }

.theme_light{ width: 50%; }

.theme_dark{ width: 50%; }

.switch_col{ display: flex; align-items: center; justify-content: center; position: relative; z-index: 2; opacity: 0.5; transition: all 0.3s ease; }

.switch_col i{ display: block;}

.theme_switch.is_light .theme_light{ opacity: 1;}
.theme_switch.is_dark .theme_dark{ opacity: 1;}


.theme_switch span{ display: block; padding:5px 4px 3px 8px; text-align: center; font-size: 14px; font-weight: 600; line-height: 20px; letter-spacing: -0.15px; color: #1A1D1F; }
 
 
.menu_hide .help_text{ display: none;}
 


.menu_hide .theme_switch span{ display: none; }
.menu_hide .theme_switch:after{ display: none !important; } 

.menu_hide .switch_col{ opacity: 1 !important; display: none; width: 100% !important; }

.menu_hide .theme_switch{ background: none; padding:4px 0px;}

.menu_hide .theme_switch.is_light .theme_light{ display: block; }
.menu_hide .theme_switch.is_dark .theme_dark{ display: block; }

.menu_hide .switch_col i{ width: 28px; height: 30px;  display: flex; align-items: center; justify-content: center; margin: auto; }

.menu_hide .switch_col i svg path{ fill: #fff !important;}

.sn_userInfo{ width: 100%; padding:10px 10px; margin-bottom: 10px; border-bottom: 1px solid #1B1B31; text-align: center; display: none; }

.menu_show .sn_userInfo{ display: block;}
.menu_show .menu{ height: calc(100vh - 370px); min-height: 120px; }

.sn_userAvatar{ width: 60px; height: 60px; margin: 0px auto 10px auto;}
.sn_userAvatar img{ width: 100%; height: 100%; display: block; object-fit: cover; }


.sn_userInfo p{ font-size: 18px; font-weight: 600; line-height: 24px; letter-spacing: -0.2px; background: linear-gradient(47deg, #FF7F02 15.59%, #FFC400 73.95%); background-clip: text;  -webkit-background-clip: text; -webkit-text-fill-color: transparent; max-width: 100%; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; }

.sn_userInfo small{ display: block; color:#FFF; font-size: 14px;  font-weight: 400; line-height: 24px;  letter-spacing: -0.16px; padding: 4px 0px;}

.sn_logout_btn{ display: inline-block; border-radius: 5px; background-color: #262639 !important; padding: 3px 32px 3px 10px; color: #6F767E;  font-size: 14px; font-weight: 400; line-height: 24px; letter-spacing: -0.16px; background: url(../../assets/images/logout_icon.svg) no-repeat right 10px center; transition:  all 0.3 ease;  }

.sn_logout_btn:hover, .sn_logout_btn.active{ background-color: #3a3a5c !important;}




@media only screen and (min-width: 0px) and (max-width: 1600px){
    .side_nav{ position: fixed; left: 0px; top: 0px; height: 100%; }
    .menu_show .sn_logo{ display: block;}
    .sn_logo_row{ padding-top:10px; padding-bottom: 22px; height: 70px; }
    .sn_menu_icon{ margin:14px 0px 0px 6px !important;}
   .menu_show.nav_overlay{ display: block;}
 }































