
.productionSummaryList{ width: 100%;}

.productionSummaryList ul{ width: 100%; display: flex; align-items: center; min-height: 80px; }

.productionSummaryList ul li{ padding-left: 8px;  width: 33.33%; border-left:1px solid #D1D1D1; }

.productionSummaryList ul li:first-child{ margin-left: 0px; padding-left: 0px; border: 0px none;}


.productionSummaryList ul li p{ font-weight: 600; font-size: 11px; padding-bottom: 6px; line-height: 1.2em;  color: #000000; }


.productionSummaryList ul li h3{ font-weight: 600; font-size: 20px;  line-height: 1.2em;  color: #000000; position: relative; } 
.productionSummaryList ul li h3 sub{ font-weight: 500; font-size: 11px; position: relative; bottom: 1px; }

@media only screen and (min-width: 1550px) {
    .productionSummaryList ul li{ padding-left: 12px; margin-left: 12px; width: 33.33%; border-left:1px solid #D1D1D1; }
    .productionSummaryList ul li p{  font-size: 14px; }
    .productionSummaryList ul li h3{ font-size: 24px;  }
    .productionSummaryList ul li h3 sub{font-size: 14px;}
}

