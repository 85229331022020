.totalAverage_header {
    width: 100%;
}

.totalAverage_header_row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 12px 0px;
}

.totalAverageRadio_box {
    padding-left: 15px;
}

.totalAverageRadio_box ul {
    display: flex;
    gap: 20px;
    align-items: center;
}

.totalAverageRadio {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    font-size: 14px;
    display: inline-block;
    color: #545454;
}

.totalAverageRadio.active {
    color: #000;
    text-shadow: 0px 0px 0.5px #000;
   
}

.totalAverageRadio:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    background: #FCFCFC;
    border: 1px solid rgba(111, 118, 126, 0.4);
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;

}

.totalAverageRadio:after {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    left: 5px;
    top: 5px;
    background: #FCFCFC;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25), inset 0px -1px 2px #DEDEDE;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.totalAverageRadio:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.totalAverageRadio.active:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.totalAverageRadio.active:before {
    background: linear-gradient(47deg, #FF7F02 15.59%, #FFC400 73.95%);
    border: 0px none;
}