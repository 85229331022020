 

 .tniNetwork_main .stf_header{ display: flex; align-items: center; padding-right: 20px; }

 .tnpNetIn_heading{ padding: 18px; border-radius: 10px 10px 0px 0px; }  

 .tnpNetIn_inner{ padding: 30px 20px; }

 .tnpNetIn_inner_heading{ text-align: center; padding-bottom: 30px; }

 .tnpNetIn_content{ padding-bottom: 36px; border-bottom: 1px solid #D1D1D1; }

 .tnpNetIn_inner_heading h2{ font-size: 48px; color: #000; font-weight: 900; line-height: 1.2em; }

 .tnpNetIn_uses_heading{ text-align: center; padding-bottom: 14px; }
 .tnpNetIn_uses_heading h2 span{ font-size: 54px; font-weight: 900; line-height: 1.2em; padding-right: 10px; color: #DD0000; }
 .tnpNetIn_uses_heading h2 strong{ font-size: 42px; color: #000; font-weight: 900; line-height: 1.2em; }

 .tnpNetIn_chart{ padding-top: 36px; }

 .tnpNetIn_chart_heading{ display: flex; align-items: center; justify-content: space-between; padding-bottom: 20px; }

 .tnpNetIn_chart_heading h2{ font-size: 20px; color: #000; line-height: 1.2em; font-weight: 500; }


 /* Infrastructure Issues */


 .tnpGRInfra_Issues_content{ padding: 20px 0px; }

 .tnpGRInfra_Issues_content ul{ display: flex; align-items: center; flex-wrap: wrap; padding-bottom: 40px; }

 .tnpGRInfra_Issues_content ul:last-child{ padding-bottom: 0px; }

 .tnpGRInfra_Issues_content ul li{ width: 33.33%; padding: 0px 14px; border-right: 2px solid #DDD;}
 .tnpGRInfra_Issues_content ul li:last-child{ border-right: 0px; }



 /* Network Insights Map */

 .tniNetworkInsightsMap{ width: 100%; }

 .tni_niMap_box{ width: 100%; }

 .niMap_top_btns{ display: flex; align-items: center; justify-content: flex-end; }

 .niMap_top_btn_icon{ padding: 4px; background: #EFEFEF; display: block; border-radius: 50%; }

 .niMap_top_btn_icon.active{ background-image: linear-gradient(46deg, #FF7F02 15.25%, #FFC400 72.33%);
    box-shadow: 0px 0px 3px #FFC400; }

.niMap_top_btn_icon.active svg{  fill: #fff;}
 .niMap_top_btn_icon svg{ width: 100%; height: auto; display: block; width: 12px; height: 12px;  }

 .niMap_top_btn_icon svg{ fill: #33383F; }
 .niMap_content{ display: flex; align-items: flex-end; justify-content: space-between; }

 .niMap_content_heading{ text-align: center; padding-right: 16px; border-right: 2px solid #DDD; }
.niMap_content_heading span{ color: #000; font-size: 20px; font-weight: 500; line-height: 1.1em; }
   
.niMap_content_heading h2{ color: #000; font-size: 36px; font-weight: 900; line-height: 1.1em; }

.tni_niMap_box{ width: 100%; }

.tni_niMap_box_heading{ display: flex; align-items: center; justify-content: space-between; }
.tni_niMap_box_heading h2{ color: #000; font-size: 22px; font-weight: 500; line-height: 1.2em; }

.tni_breakdown_allBtns{ padding-top: 10px; }
 

.tniNetworkInsightsMap_h .mapchart_box {  height: 786px !important; }

.tniNetworkInsightsTable{ width: 100%; }


.table_top_info{ background-color: #F7F7F7; }
.table_top_info ul{ display: flex; } 
.table_top_info ul li{ padding: 0px 15px; }
 

.table_top_info ul li span{ padding: 15px 0px; color: #000;  font-size: 14px; font-weight: 700; line-height: 1.2em; display: block; }


