
.ts_ext_main{ width: 100%; padding-bottom: 20px; display: flex; flex-wrap: wrap; }

.ts_ext_left{ width: 28%; }
.ts_ext_middle{width: 46%; padding-left: 15px;}

.ts_ext_right{ width: 26%; border: 1px solid #EEE;
    background: #19AAF8; border-bottom: 5px solid #fff;  }


.ts_ext_table{ width: 100%;}
.ts_ext_table table{ width: 100%; border-collapse: collapse; }
.ts_ext_table table tr{ border-bottom: 5px solid #fff;}
.ts_ext_table table tr td{ font-weight: 400; font-size: 16px; line-height: 1.2em;   background: #19AAF8; color: #fff; padding: 14px 10px 14px 20px; }
.ts_ext_table table tr td p{  font-weight: 400; color: #000000; position: relative; }
.ts_ext_table table tr td strong{ display: block; line-height: 1em; font-weight: 600; font-size: 25px; position: relative;}
.ts_ext_table table tr td sub{ font-size: 50%; position: relative; line-height: 16px; bottom: 1px;}
.ts_ext_table table tr td:first-child{ width: 55%;}


.ts_ext_table table tr:nth-child(even) td{ background: #C3EBFF; color: #000000; }
 
.ts_networks_content{ width: 100%; display: flex; flex-wrap: wrap; align-items: flex-start;}

.ts_networks_row{width: 68%; padding-bottom: 10px; padding-right: 10px; }
.ts_networks_cell{ width:100%; margin-bottom: 10px;   }
.ts_networks_cell:last-child{ margin-bottom: 0px;}
.ts_network_box{ width: 100%;  background: #C3EBFF; padding: 20px; }

.ts_network_box ul{display: flex; justify-content: space-between; }
.ts_network_box ul li{   padding: 0px 2% 0px 4%; border-left: 1px solid #000; position: relative; }
.ts_network_box ul li:first-child{ border-left:0px none; padding-left: 0px; margin-left: 0px; }


.ts_network_box ul li p{font-weight: 400; font-size: 16px; line-height: 16px; padding-bottom: 6px; color:#000000;}

.ts_has_status{ position: relative; padding-left: 16px;  }
.ts_status{ width: 10px; height: 10px; background: #01A340; border-radius: 50%; position: absolute; left: 0px; top:1px; display: block; }  
.ts_status.ts_offline{ background: #F13534; }

.ts_network_box ul li strong{ font-weight: 600; display: block; font-size:28px; line-height: 28px; color:#000000; }

.ts_network_box h4{ min-height: 50px; font-weight: 600;  font-size: 28px; color: #000000; display: flex; align-items: center; }
.ts_network_box h4 i{ display: block; width: 36px; height: 36px;  flex-shrink: 0; margin-right: 10px; font-weight: 900;  font-size: 18px; line-height: 36px;  text-align: center; color: #fff; background-color: #000000; border-radius: 50%; letter-spacing: 0px; }

.ts_network_box_dark {background-color: #19AAF8;}

.ts_network_box_dark.ts_network_box ul li{ border-color: #fff;}
.ts_network_box_dark.ts_network_box ul li strong{ color: #fff;}


.ts_network_solar{width: 100%; margin-top: 20px;}


.ts_ext_charts_row{ width: 100%; display: flex; justify-content: flex-start; flex-wrap: wrap; gap: 5px; }

.ts_ext_chart_box{ width: calc(50% - 6px); background: #FFFFFF; border: 1px solid #EEEEEE; padding: 16px 12px; position: relative; display: flex; }


.ts_ext_text_info{ width: 106px; }

.ts_ext_text_info i{ display: block; padding-bottom: 10px; }
.ts_ext_text_info i img{ max-width: 50px; max-height: 50px; display: block;  }

.ts_ext_text_info p{ font-weight: 400; font-size: 13px; line-height: 19px; color: #000; padding-bottom: 4px; }

.ts_ext_text_info h3{ font-weight: 400; font-size: 24px; font-weight: 600; line-height: 1.2em; color: #000;    }
.ts_ext_text_info strong{ font-weight: 400; font-size: 16px; font-weight: 600; line-height: 1.2em; color: #000;    }

.ts_ext_chart_right{ width: calc(100% - 96px); }

.ts_ext_chart{ height: 135px; width: 100%; }


.ts_ext_chart_percent {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  .ts_ext_chart_percent span {
    padding-right: 15px;
    padding-left: 14px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px; padding-top: 1px;
    color: #4FBF67;
    background: url(../../assets/images/exc_rate_up_arrow.svg) no-repeat left center;
    background-size: 10px auto !important;
  }
  
  .ts_label_sel {
    display: inline-block;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
    background: #BCEAFF;
   
  }

  .ts_label_sel select{  padding: 2px 20px 2px 6px;  background: url(../../assets/images/dropdown_arrow.svg) no-repeat right 5px center;
    background-size: 10px auto; }

  

  .ts_networks_chart_box{ width: 32%;   }

.ts_kt_header{ width: 100%; display: flex;  justify-content: space-between; align-items: center; padding: 12px 15px; color: #fff; }

.ts_kt_header small{ color: #FFF; font-size: 14px; display: block; margin-right: auto; line-height: 1.4em; font-weight: 400; min-width: 120px; }

.ts_kt_header .ts_ext_chart_percent span{ color: #fff;   background: url(../../assets/images/exc_rate_up_arrow_white.svg) no-repeat left center;}



.ts_kt_header .ts_label_sel{ background-color: #fff;}


.exdb_emis_box{ display: flex; align-items: center; justify-content: center; min-height: 160px; padding: 10px 15px;}
.exdb_emis_box span{ max-width: 50px; max-height: 50px; display: block;}
.exdb_emis_box span svg{ display: block; width: 100%; height: 100%;}

.exdb_emis_box h3{ position: relative; padding-left: 18px; font-size: 42px; font-weight: 600; color: #fff;}
.exdb_emis_box h3 b{  font-weight: 600; }
.exdb_emis_box h3 sub { font-size: 44%; position: relative; line-height: 22px; bottom: 1px; }

.ts_ext_right .ts_ext_table table tr td{color: #000; background: #C3EBFF; }
.ts_ext_right .ts_ext_table table tr{ border-color: #19AAF8;}



.exe_summary_box{ background-color: #fff; box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px; padding: 10px; margin-top: 10px; }

.exe_top_radios_row {  width: 100%; display: flex; align-items: flex-start;  padding-bottom: 12px; margin-bottom: 10px; border-bottom: 1px solid rgba(0, 0, 0, 0.25); }

 
.exe_top_radios_row ul { display: flex;  gap: 20px; align-items: center; }

.exe_summary_radio {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  font-size: 14px;
  display: inline-block;
  color: #545454;
}

.exe_summary_radio span{ display: block; position: relative; }


.exe_summary_radio span::after{ content: ''; width: 100%; position: absolute;
   
  height: 4px;
  left: 0px;
  bottom: -6px;
  background: linear-gradient(47deg, #FF7F02 15.59%, #FFC400 73.95%);
  border-radius: 32.0254px; opacity: 0; transition: all 0.175s ease; }

  .exe_summary_radio:hover span:after, .exe_summary_radio.active span:after{ opacity: 1;}

.exe_summary_radio.active {
  color: #000;
  text-shadow: 0px 0px 0.5px #000;
 
}

.exe_summary_radio:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  background: #FCFCFC;
  border: 1px solid rgba(111, 118, 126, 0.4);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;

}

.exe_summary_radio:after {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  left: 5px;
  top: 5px;
  background: #FCFCFC;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25), inset 0px -1px 2px #DEDEDE;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.exe_summary_radio:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.exe_summary_radio.active:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.exe_summary_radio.active:before {
  background: linear-gradient(47deg, #FF7F02 15.59%, #FFC400 73.95%);
  border: 0px none;
}


.exe_editData_btn{ background-color: #fff; border: 1px solid #000;  font-weight: 500;  font-size: 15px; line-height: 18px; text-align: center; color: #000; padding: 6px 12px 6px 30px; cursor: pointer; transition: all 0.3s ease; display: block; width: 100%; background: url(../../assets/images/edit_data_icon.svg) no-repeat left 6px center; background-size: 20px auto; }


 

.ep_emissions_headlines{ width: 100%; }

.ep_emissions_headlines h2{ font-weight: 900; font-size: 50px;  line-height: 1em; padding-bottom: 10px;}
.ep_emissions_headlines h2 b{ font-weight: 900;line-height: 52px; display: inline-block; text-align: right; vertical-align: top; background: url(../../assets/images/graph_arrow_up.svg) no-repeat left 24px; background-size: 18px auto !important; padding-left: 30px; }

.ep_emissions_headlines h2.ep_negative b{  background: url(../../assets/images/graph_arrow_down.svg) no-repeat left 24px; }

.ep_emissions_headlines h2 small{ display: block; color: #01A340 ; font-size: 20px; font-weight: 400; line-height: 20px; }

.ep_emissions_headlines h2.ep_negative small{ color: #DD0000;}

.ep_emissions_headlines strong{ display: block; color: #000 ; font-size: 24px; font-weight: 500; line-height: 1.2em; padding-bottom: 6px; }

.ep_emissions_headlines p{ color: #000 ; font-size: 16px; font-weight:400; line-height: 1.2em; padding-bottom: 20px;  }


.exe_smry_charts_content{ width: 100%; display: flex; position: relative; align-items: center; }

.exe_smry_charts_content:after{ content: ""; display: block; position: absolute; left: 50%; top: 0px; height: 100%; width: 1px; background-color: #D1D1D1; }

.exe_smry_charts_left{ width: 50%; padding-right: 40px; }

.exe_smry_charts_right{ width: 50%; padding-left: 40px; }

.exe_energy_box{ width: 100%; background: #F8F8F8; padding: 15px; display: flex; align-items: center; }

.ep_emissions_headlines{ width: 60%; padding-right: 20px; }

.ep_emissions_chart_out{ width: 50%; height: 100%;}

.exe_smry_consumption_chart{ max-width: 540px; margin: auto;}


.exe_border_none.exe_smry_charts_content:after{ display: none; }


.saving_reduction_heading{  margin-right: auto; width: auto; margin-bottom: 30px; text-align: center;}

.saving_reduction_heading ul{ display: inline-flex; justify-content: center; align-items: center;  padding: 15px 20px 12px 20px; background: #F8F8F8; }
.saving_reduction_heading ul img{ padding-right: 10px; max-width: 40px; }
.saving_reduction_heading ul li{ padding: 0px 40px 0px 40px; border-right: 1px solid #C3C6C9; display: flex; justify-content: center; align-items: flex-start; text-align: left; }
.saving_reduction_heading ul li:first-child{ padding-left: 0px; }
.saving_reduction_heading ul li:last-child{ border-right: none; padding-right: 0px; }

.reduction_heading h2{ font-weight: 500; font-size: 22px; line-height: 1.2em; color: #19AAF8; }

.reduction_heading p{ font-size: 16px; line-height: 1.5em; color: #000000; }

.exe_saving_charts_row{ width: 100%; display: flex; flex-wrap: wrap; }

.exe_saving_cell{ width: 30%; padding: 0px 30px; }

.exe_saving_col3{ width: 40%;}

.exe_saving_cell .exe_smry_consumption_chart{ max-width: 300px; margin: auto; }
.exe_saving_cell.exe_saving_col3 .exe_smry_consumption_chart{ max-width: none; margin: auto; }











