
.management_reports{ width: 100%; }

.reports_targets{ width: 100%; } 
 
.rt_box{ background-color: #FFF; }

.rt_ul_li{ height: 100%; } 
 

.management_reports_detail{ width: 100%; }

.mr_detail_content_heading{ display: flex; align-items: center; justify-content: space-between; padding-bottom: 30px; max-height: 74px; }

.mr_detail_content_heading h4{ color: #000; font-size: 22px; font-weight: 500; line-height: 1.2em; }

.mr_detail_content_heading span{ color: #000; font-size: 20px; font-weight: 500; line-height: 1.2em; padding: 10px; background: #FFD9D9; }

.mr_detail_content_reports{ width: 100%; }

.mr_detail_content_reports ul li{ padding: 6px 0px 6px 10px; margin-bottom: 10px; }

.mr_detail_content_reports ul li:nth-child(odd){
    background-color: #F7F7F9;
}

.mr_detail_cr_data{ display: flex; align-items: center; justify-content: space-between; }

.mr_detail_cr_data span{ color: #000; font-size: 22px; font-weight: 400; line-height: 1.2em; }

.mr_detail_cr_data .viewBtn{ color: #FFF; font-size: 16px; font-weight: 400; line-height: 1.2em; padding: 10px; background-color: #000; border-radius: 4px; }



.r_pagination{ width: 100%; padding-top: 10px; }

.r_pagination_ul{ display: flex; align-items: center; justify-content: center; }
.r_pagination_li{ padding: 0px 6px; }

.r_pagination_li button{ padding: 5px 4px;font-size: 14px;  font-weight: 700; line-height: 20px; border-radius: 4px; border: 1px solid #DFE3E8; background: #FFF; width: 30px; height: 30px; display: flex; align-items: center; justify-content: center; }

.r_pagination_li button.active{ color: #FFF; background: #000; }

.r_pagination_li button svg{ width: 100%; height: auto; max-width: 24px; display: block; }
