
.login_body{ height: 100vh; width: 100%; background: rgba(19, 19, 37, 1); overflow-y: auto; position: relative; }

.login_main{ width: 100%; min-height: 100%; display: flex; align-items: center; }

.login_left{ width: 60%; height: 100%; position: fixed; top: 0;  background: url(../../assets/images/login_bg.png) no-repeat center center; background-size: cover; }

.login_right{ width: 40%; height: 100%; padding: 20px; margin-left: auto; }

.login_section{ width: 100%; height: 100%; display: flex; justify-content: center; align-items: center;  }

.login_section_inner{ max-width: 410px; width: 100%; }

.login_logo{ width: 100%; }

.login_logo figure{ max-width: 240px; display: block; }
.login_logo figure img{ width: 100%; max-width: none; display: block; height: auto; }

.login_form_main{ width: 100%;}
.login_form_main h5{ font-size: 26px; line-height: 1.4em; letter-spacing: -0.5px; color: rgba(255, 255, 255, 1); padding: 70px 0px 40px 0px; }

.login_form{ width: 100%; padding-bottom: 16px; }

.login_field{ width: 100%; margin-bottom: 15px; position: relative; }

.floating_label_login{ font-size: 14px; line-height: 16px; font-weight: 700; color: #90989F;
   position: absolute; top: 24px; left: 20px;
   pointer-events:none;
   transition:0.175s ease all; 
   -moz-transition:0.175s ease all; 
   -webkit-transition:0.175s ease all; }

.login_field input{ width: 100%; height: 62px; padding: 24px 20px 16px 20px; background: #242731; 
  border:2px solid var(--neutral-05, #33383F); border-radius: 10px; font-size: 16px; line-height: 20px;
   color: rgba(255, 255, 255, 1); transition:0.175s ease all;  -moz-transition:0.175s ease all; 
   -webkit-transition:0.175s ease all; background-repeat: no-repeat !important; background-position: right 14px center !important;}

.login_field input.check_mark, .login_field input:focus.check_mark{ background-image: url(../../assets/images/input_check.svg);  background-size: 16px auto; }

.login_field input:focus{ background: #43454F; border-color: #FFC400; }

.login_field input:focus ~ .floating_label_login{ top: 10px; }
.login_field input:not(:placeholder-shown) ~ .floating_label_login { top: 10px; }

.forget{ width: 100%; text-align: right; padding: 8px 0px 24px 0px; }

.loginLink{ color: rgba(144, 152, 159, 1); font-size: 12px; line-height: 1.4em; font-weight: 700;  padding-left: 28px; transition: all 0.2s ease;
   background: url(../../assets/images/link.svg) no-repeat left center; background-size: 16px auto; }

 .loginLink:hover{ color: #fff; text-decoration: underline;}

.login_btn_main{ width: 100%; padding-bottom: 16px; }

.login_btn{ width: 100%; font-size: 16px; color: rgba(17, 19, 21, 1); line-height: 24px; font-weight: 600; padding: 10px;
  background: var(--linear, linear-gradient(25deg, #FF7F02 0%, #FFC400 100%)); border-radius: 12px !important; cursor: pointer; }

.sign_up{ width: 100%; display: flex; justify-content: center; align-items: center; }

.sign_up p{ font-size: 12px; font-weight: 700; line-height: 1.4em; color: rgba(255, 255, 255, 1); padding-right: 8px; }

.error_text{ color: red;}

input.error{ border-color: red !important;}









