
.ampButtonsGroup{ width: auto; text-align: right;}

.ampButtonsBox{ display: inline-flex; justify-content: center;  gap: 2px; background: #EFEFEF;  border-radius: 40px; align-items: center;  padding: 4px 9px 4px 4px; }

.ampButton{ border-radius: 32px;  padding: 6px 10px 4px 10px; font-weight: 500;  font-size: 12px;  line-height: 1.2em; text-align: center; color: #FFFFFF; cursor: pointer; color: #33383F; }

.ampButton:hover{ background: #F7F7F7; }

.ampButton.active{ background: #FF8901;  color: #FFFFFF; }















