.header_main{ position: sticky; top: 0px; z-index: 999; width: 100%; background: #FFFFFF;  margin-bottom: 6px; box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04); }

.headerMain_inner{ width: 100%; display: flex; padding: 10px 0px; align-items: center; justify-content: space-between; }

.headerMain_logo{ width: 100%; max-width: 150px;}
.headerMain_logo span, .headerMain_logo a{ display: block;}
.headerMain_logo img{ display: block; max-width: none; width: 100%; height: auto; }

.headerMain_title{ margin-right: auto; margin-left: 12px; padding-left: 12px; margin-top: 20px; position: relative; }

.headerMain_title:before{ width: 1px; height: 14px; content: ""; position: absolute; left: 0px; top: 2px; background: #000000; }

.headerMain_title span{ font-weight: 400; display: block;  font-size: 16px; line-height: 20px; color: #000000;  }

.headerMain_right{ margin-left: auto; display: flex; align-items: flex-end; }




