
.sts_topHeader{ width: 100%; background: #FFF; box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.15); display: flex; align-items: center; padding-right: 20px; margin-bottom: 15px; }


.sts_goodSite{ height: 100%; background: #BFE8CF; background: #BFE8CF; min-height:50px; display: flex; align-items: center; justify-content: center; padding: 10px 25px; }

.sts_goodSite span{ color: #01A340; line-height: 1.4em; display: block; font-size: 15px; font-weight: 700; }

.title{ color: #000;  font-size: 15px; font-weight: 500; line-height: 1.4em; padding: 0px 12px; }


.status_border{ padding-right: 10px; border-right: 1px solid #8C8C8C; }


.labels{ display: flex; align-items: center; }

.labels span{ padding-left: 10px; margin-left: 10px; border-left: 1px solid #8C8C8C; color: #000;  font-size: 14px; font-weight: 400; line-height: 1.5em; display: flex; align-items: center; }

.labels span:first-child{ border-left: 0px none;  }

.labels span i { width: 14px; height: 14px;  border-radius: 50%;  background-color: #01A340; margin-left: 10px; }


.sts_date_status{ margin-left: auto; color:#33383F;  font-size: 11px; font-weight: 500; display: block; line-height: 1.4em; }


.box_small{ width: 19%; max-width: 19%;}

.moreBtn{ color: #FF7F02; font-size: 12px; display: inline-flex; align-items: center; gap: 5; line-height: 1.4em; font-weight: 500; }
.moreBtn svg{ display: block; padding-top: 1px;}
.moreBtn:hover{ text-decoration: underline;}


.sts_infra_row ul{ width: 100%; justify-content: space-around;}

.sts_infra_row ul li{ padding-left: 12px; margin-left: 12px; border-left: 1px solid #8C8C8C; }

.sts_infra_row ul li:first-child{ padding-left: 0px; margin-left: 0px; border-left: 0px none;}

.infrastructure_box{ width: 100%; text-align: center; } 

.infrastructure_box h4{ position: relative; color: #000; font-size: 16px; font-weight: 500; line-height: 1.4em; padding-bottom: 10px; display: flex; align-items: center; text-align: left;}

.infrastructure_box h4 i{ width: 26px; height: 26px; display: block; border-radius: 50%; margin-right: 8px; display: flex; align-items: center; justify-content: center; }

.infrastructure_box h4 i img{ display: block; margin: auto; max-width:18px; max-height: 18px;}


.infrastructure_box h3{ position: relative; color: #000; font-size: 32px; font-weight: 500; line-height: 1em; padding-bottom: 8px; display: flex; justify-content: center; align-items: center; }


.infrastructure_box h3 sub{ font-size: 50%; display: inline-flex; padding-left: 5px; }

.infrastructure_box label{ font-weight: 500; display: block; font-size:14px}



.alaramsNo{ height: 100px; display: flex; flex-direction: column; align-items: center; justify-content: center; }

.alaramsNo h3{ color: #F13534; text-align: center; font-size:50px; font-weight: 500; line-height: 1.2em; }

.alaramsNo span{ color: #000; text-align: center; font-size:16px; font-weight: 500; line-height: 1.2em; }

.ss_targetsBox{ width: 100%; color: #fff; padding: 0px; transition: all 0.2s ease; }


.ss_targetsBox.active{ background-color: #fff !important; border-radius: 5px 5px 0px 0px !important; transform: translateY(8px); transition: all 0.2s ease; }

.ss_targetsBox .moreBtn{ color: #fff; margin-right: 10px; margin-bottom: 5px; }

.targets_headlines{ width: 100%; display: flex; align-items: center; justify-content: space-between; padding: 10px 15px; margin-bottom: 12px; border-bottom: 1px solid rgba(255, 255, 255, 0.35); border-radius: 5px; }

.targets_headlines strong{ display: flex; width: 100%; align-items: flex-start; font-size: 14px; font-weight: 600; line-height: 1.2em; }

.targets_headlines strong i{ display: block; max-width: 20px; width: 100%; flex-shrink: 0; margin-right:8px; }

.targets_headlines strong i img{ display: block; max-width: 22px; max-height: 18px;}

.target_percent{ font-size: 11px; font-weight: 700;  line-height: 16px;  letter-spacing: -0.12px; padding-left: 16px; background: url(../../assets/images/arrow_up.svg) no-repeat left center; background-size: 10px auto !important; }

.target_percent.down{ background: url(../../assets/images/arrow_down.svg) no-repeat left center; }

.targetsBox_data{ width: 100%; text-align: center;   padding: 10px 10px 20px 10px; color: #FFF; }

.targetsBox_data h2{  font-size: 34px; align-items: center; justify-content: center;  font-weight: 500; line-height: 1.2em; display: flex; align-items: center; }

.targetsBox_data h2 sub{ font-size: 50%; display: inline-flex; margin-left: 3px;}


.targetsBox_data p{ font-size: 18px; align-items: center;  font-weight: 500; line-height: 1.2em; }


.viewTrend_showBox{ width: 100%; padding-bottom: 10px; margin-bottom: 10px; position: relative;   }


.closeBoxBtn{ width: 40px; height: 40px; border-radius: 50%; background-color: #F4F4F4 !important; background: url(../../assets/images/close_icon.svg) no-repeat center center; cursor: pointer; }


.trends_chartContent div, .trends_chartContent svg{ overflow: initial !important;  }

.slc_updatedDate{ color:#33383F;  font-size: 12px; font-weight: 500; }

.sts_slc_chrtData{ width: 100%; text-align: center; position: relative; margin-top: -106px; }

.sts_slc_chrtData h3{ display: flex; align-items: center; justify-content: center; line-height: 1.2em; color: #000; font-size:36px; font-weight: 900; padding-bottom:18px; }

.sts_slc_chrtData h3 sub{ font-size: 35%; font-weight: 600; padding-left: 6px; }


.sts_slc_chrtData p{ color: #33383F;  font-size: 12px;  font-weight: 500; padding-bottom: 4px; line-height: 1.4em; }

.sts_slc_chrtData p strong{ font-weight: 700; padding-left: 10px;}

.sss_alarams_chart{ max-width: 100%;}

.viewCloseBoxSmallPos{ position: relative;}

.viewCloseBoxSmallPos .closeBoxBtn{ position: absolute; right: 0px; top: 50%; margin-top: -17px; width: 34px; height: 34px; background-size: 18px auto !important; } 

.reportsTempTabs_main .targets_headlines strong{ min-height: 33px;  align-items: center;}
.reportsTempTabs_main .targets_headlines strong i{ margin-top: -4px; }
.reportsTempTabs_main .targets_headlines strong i img{ max-width: 18px; }


