.fuelAnomalies_list{ width: 100%; max-width: 320px; margin: auto; min-height: 266px; display: flex; align-items: center;} 
 
.fuelAnomalies_list ul li{ width: 100%; padding-bottom: 20px;}

.fuelAnomalies_list ul li:last-child{ padding-bottom: 0px;}

.fuelAnomalies_row{ width: 100%; display: flex; flex-wrap: wrap; align-items: center; }

.fuelAnomalies_img{ width: 60px; margin-right: 15px;}
.fuelAnomalies_img svg, .fuelAnomalies_img img{ display: block; max-width: none; width: 100%; height: auto;}

.fuelAnomalies_text{ width: calc(100% - 75px); padding-top: 2px;}

.fuelAnomalies_text p{ font-weight: 600; font-size: 14px; padding-bottom: 4px; line-height: 1.2em; color: #000000; }

.fuelAnomalies_text h3{ font-weight: 600; position: relative; font-size: 26px; line-height: 1.2em; color: #000000; }

.fuelAnomalies_text h3 sub{ position: relative; font-weight:500; font-size: 14px; top: -1px;  }




