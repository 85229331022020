 
 :root{
    --border_color: #8C8C8C;
    --border_color2: #EFEFEF;
    --border_color3: #DFE4EA;
    
    
    --color_black: #000000;
    --color_black2: #0E283F;
    --color_black3: #33383F;
    --color_dark_black: #32323E;
    --color_white: #fff;
    --color_black_pearl: #111315;
    --color_black_russian: #131325;

    
    --color_blue_slate: #7243D0;
    --color_blue_navy: #0058FF;
    --color_blue2: #5967FC;
    --color_blue3: #45A4DC;
    --color_blue4: #107CFF;
    --color_blue5: #107DFF;
    --color_blue6: #2F79BD;
    --color_sky_blue: #C3EBFF;

    --color_deep_skyBlue: #00B2FF;
    

    
    
    --color_red: #F20100;
    --color_red2: #DD0000;
    --color_red3: #FE3030; 
    --color_red4: #EA2B55;

    --color_dark_red: #D70000;

    --color_orange: #F55836;
    --color_orange2: #F57F3C;
    --color_orange3: #FF8301;
    --color_orange4: #FF7F02;
    --color_orange5: #FF9E01;
    --color_pink_dark: #E8B5A8;
    --color_pink_light: #FCE6E6;

    
    --color_green_pigment: #01A340;
    --color_green_fiji: #6E7C1A;
    --color_green_sea: #32864B;
    --color_green_apple: #57C139;
    --color_green: #247818;
    --color_green2: #91cc75;
    --color_green3: #17A700;
    --color_green4: #21AB4A;

    
    --color_green_light: #00E308;

    --color_green_aero: #BFE8CF;
    
    --color_tuna_dark: #373737;
    --color_grey_tuna: #464E5C;
    --color_grey_dark: #757575;
    --color_grey_dark2: #F2F2F2;
    --color_gray_dark3: #6b6b6b; 
    --color_gray_dark4: #A36464;
    --color_grey_light: #F7F7F7;
    --color_gray_light2: #F1F1F1;
    --color_gray_light3: #C1C1C1;
    --color_gray_light4: #94A3B8;
    --color_gray_light5: #F8F8F8;
    
    --color_grey_cement: #88745B;
    --color_gray2: #DDD; 
    
    

    
    
    --color_yellow : #F1B400;
    --color_light_blue: #5C9AD5;
    --color_light_blue2: #E9F7ED;
    

    --color_roil_blue: #AC70D0;
    --color_dark_brown: #8F5721;

    --color_purple: #800080;
    --color_purple2: #9E62BA;

    --color_purple_dark: #A472B4;


    --color_pink: #fdf2f2;

    --color_dark2: #334155;
    --color_dark3: #475569; 

 }


.cstm_b_modal .modal-header{  background: var(--color_black_russian); color: var(--color_white);  }

.cstm_b_modal .btn-close{  background: url(../assets/images/modal_close.svg) no-repeat center center !important; opacity: 1;  background-size: 18px auto !important;}

.cstm_b_modal .modal-title{  font-size:18px;  font-weight: 500; line-height: 1.4em; }


.addFuelDetails_pop .modal-dialog{ max-width: 700px; }

.cstm_b_modal .modal-footer{ border-top: 0px none !important;}


.ps_title_border{ color: #000; font-size: 14px; font-weight: 500; line-height:1.4em; margin-bottom: 26px; position: relative; border-bottom: 1px solid var(--border_color); }

.ps_title_border span{ position: relative; z-index: 1; background-color: var(--color_white); display: inline-flex; padding-right: 10px; margin-bottom: -2px; transform: translateY(6px); } 

.form-floating>label{ color: #000; font-size: 13px;  font-weight: 400;  padding: 10px 15px 0px 15px !important;  }

.form-floating>label:after{ display: none !important;}

.form-floating>.form-control, .form-floating>.form-control-plaintext{ padding:18px 15px 2px 15px !important; height: 44px !important;  min-height: 44px !important; color: #000;  font-size: 14px;  font-weight: 600; }

 

.form-floating>.form-control-plaintext~label, .form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-select~label{ color: #107DFF !important}

.stf_addbtn{ padding-left: 40px !important; background-image: url(../assets/images/plus_icon.svg);
background-repeat: no-repeat; background-size: 18px auto;  background-position: left 10px center; }

.sts_status_row{ display: flex; align-items: center; }

.sts_status_box{ width: 50px; height: 50px; position: relative; background: var(--color_green_pigment); color: var(--color_white);
text-align: center; font-size: 7px; font-weight: 600;  text-transform: uppercase; padding: 2px 0px; }

.sts_status_box span{ font-size: 7px !important; display: block; line-height: 16px !important; padding-bottom: 2px !important; width: 100% !important; text-align: center !important; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; color: var(--color_white) !important; padding: 0px !important; }

.sts_status_box i{ display: block; width: 100%; height: 26px; background: url(../assets/images/sts_connected.svg) no-repeat center center; background-size: auto 100% !important; }

.sts_solar{  background: var(--color_yellow); }
.sts_status_box.sts_solar i{  background: url(../assets/images/sts_solar.svg) no-repeat center center; }

.sts_battery{  background: var(--color_blue_navy); }
.sts_status_box.sts_battery i{  background: url(../assets/images/sts_battery.svg) no-repeat center center; }


.sts_main{  background: var(--color_blue_slate); }
.sts_status_box.sts_main i{  background: url(../assets/images/sts_mains.svg) no-repeat center center; }


.sts_genset{  background: var(--color_green_sea); }
.sts_status_box.sts_genset i{  background: url(../assets/images/sts_genset.svg) no-repeat center center; background-size:  auto 22px !important; }


.sts_rectifier{  background: var(--color_grey_cement); }
.sts_status_box.sts_rectifier i{  background: url(../assets/images/sts_rectifier.svg) no-repeat center center; background-size: 34px auto !important; }


.sts_outage{  background: var(--color_red2); }
.sts_status_box.sts_outage i{  background: url(../assets/images/sts_outage.svg) no-repeat center center; }


.sts_wind{  background: var(--color_green_fiji); }
.sts_status_box.sts_wind i{  background: url(../assets/images/sts_wind.svg) no-repeat center center; }


.sts_csu{  background: var(--color_blue2); }
.sts_status_box.sts_csu i{  background: url(../assets/images/sts_csu.svg) no-repeat center center; }


.sts_status_small_row .sts_status_box{ width: 32px; height: 32px;}
.sts_status_small_row .sts_status_box span{ font-size: 5px !important; line-height: 8px !important;}
.sts_status_small_row .sts_status_box i{ height: 16px; }
.sts_status_small_row .sts_status_box.sts_rectifier i{ background-size: 14px auto !important; }


.box_info_flexible{ width: 100%; display: flex; justify-content: space-between; align-items: center; padding-bottom: 10px; margin-bottom: 10px; border-bottom: 1px solid var(--border_color); }

.box_info_flexible .box_info{ position: initial;}


.label10{ font-size: 10px; line-height: 1.2em; color: var(--color_black); }
.label11{ font-size: 11px; line-height: 1.2em; color: var(--color_black); }
.label12{ font-size: 12px; line-height: 1.2em; color: var(--color_black); }
.label13{ font-size: 13px; line-height: 1.2em; color: var(--color_black); }
.label14{ font-size: 14px; line-height: 1.2em; color: var(--color_black); }
.label15{  font-size: 15px; line-height: 1.2em; color: var(--color_black); }
.label16{  font-size: 16px; line-height: 1.2em; color: var(--color_black); }
.label17{  font-size: 17px; line-height: 1.2em; color: var(--color_black); }
.label18{  font-size: 18px; line-height: 1.2em; color: var(--color_black); }
.label19{  font-size: 19px; line-height: 1.2em; color: var(--color_black); }
.label20{  font-size: 20px; line-height: 1.2em; color: var(--color_black); }
.label22{  font-size: 22px; line-height: 1.2em; color: var(--color_black); }
.label25{  font-size: 22px; line-height: 1.2em; color: var(--color_black); }
.label30{  font-size: 30px; line-height: 1.2em; color: var(--color_black); }
 
.label60{  font-size: 60px; line-height: 1.2em; color: var(--color_black); }
.label38{  font-size: 38px; line-height: 1.2em; color: var(--color_black); }
.label40{  font-size: 40px; line-height: 1.2em; color: var(--color_black); }




.weight1{ font-weight: 100 !important;}
.weight2{ font-weight: 200 !important;}
.weight3{ font-weight: 300 !important;}
.weight4{ font-weight: 400 !important;}
.weight5{ font-weight: 500 !important;}
.weight6{ font-weight: 600 !important;}
.weight7{ font-weight: 700 !important;}
.weight8{ font-weight: 800 !important;}
.weight9{ font-weight: 900 !important;}


.bg_blue{ background-color: var(--color_blue_slate) !important;   } 
.bg_blue3{ background-color: var(--color_blue3) !important;   } 
.bg_sky_blue{ background-color: var(--color_sky_blue) !important; } 

.bg_deep_skyBlue{ background-color: var(--color_deep_skyBlue) !important; }

.bg_purple{ background-color: var(--color_purple) !important; }

.bg_purple2{ background-color: var(--color_purple2) !important; }



.bg_green{ background-color: var(--color_green_sea) !important;}
.bg_green_light{ background-color: var(--color_green_light) !important;} 
.bg_green3{ background-color: var(--color_green3) !important; }
.bg_green_pigment{ background-color: var(--color_green_pigment);}
.bg_blue_navy{ background-color: var(--color_blue_navy) !important;}
.bg_yellow{ background-color: var(--color_yellow) !important;}
.bg_grey_light{ background-color: var(--border_color) !important;}

.bg_grey_cement{ background-color: var(--color_grey_cement) !important;}
.bg_grey_tuna{ background-color: var(--color_grey_tuna) !important;}
.bg_orange{ background-color: var(--color_orange) !important;}

.bg_orange2{ background-color: var(--color_orange2) !important;}

.bg_pink{ background-color: var(--color_pink) !important; } 
.bg_light_pink{ background-color: var(--color_pink_light) !important; } 

.bg_black{ background-color: var(--color_black); }
.bg_red1{ background-color: var(--color_red) !important;}

.bg_white{background-color: var(--color_white);}

.bg_light_blue{ background-color: var(--color_light_blue); }

.bg_light_blue2{ background-color: var(--color_light_blue2); }


.bg_roil_blue{ background-color: var(--color_roil_blue); }

.bg_gray{ background-color: var(--border_color2) !important;  } 

.bg_red{ background-color: var(--color_red2); }
.bg_red3{ background-color: var(--color_red3); }

.bg_green{ background-color: var(--color_green); }
.bg_gray_light2{ background-color: var(--color_gray_light2); }




.bg_grey_dark{ background-color: var(--color_grey_dark); }
.bg_grey_dark2{ background-color: var(--color_grey_dark2); }
.bg_grey_light2{ background-color: var(--color_grey_light); }
.bg_gray_light3{ background-color: var(--color_gray_light3) !important; }
.bg_tuna_dark{ background-color: var(--color_tuna_dark); }
.bg_dark_brown{ background-color: var(--color_dark_brown); }

.bg_blue4{ background-color: var(--color_blue4) !important;   } 
.bg_blue5{ background-color: var(--color_blue5) !important; }
.color_blue6{ background-color: var(--color_blue6) !important; }

.bg_gray_dark4{ background-color: var(--color_gray_dark4); }
 
.bg_gray_dark5{ background-color: var(--color_gray_light5); }



 

.color_black{ color: var(--color_black) !important;}
.color_black2{  color: var(--color_black2);}
.color_black3{ color: var(--color_black3);}

.color_dark_black{ color: var(--color_dark_black); }
.color_white{ color: var(--color_white); }
.color_blue{ color: var(--color_blue_slate);} 

.color_green{ color: var(--color_green_sea);} 
.color_green3{ color: var(--color_green3) !important;} 
.color_green_pigment{ color: var(--color_green_pigment);}

.color_green4{ color: var(--color_green4) !important; }
.color_red{ color: var(--color_red);}
.color_red2{ color: var(--color_red2);}

.color_dark_red{ color: var(--color_dark_red); }
.color_blue_navy{ color: var(--color_blue_navy);}
.color_yellow{ color: var(--color_yellow);}
.color_grey_cement{ color: var(--color_grey_cement);}
.color_grey_tuna{ color: var(--color_grey_tuna);}
.color_gray_light3{ color: var(--color_gray_light3) !important; }
.color_orange{ color: var(--color_orange);}

.color_orange3{ color: var(--color_orange3); }
.color_orange4{ color: var(--color_orange4); }

.color_orange5{ color: var(--color_orange5); }

.color_gray_dark3{ color: var(--color_gray_dark3) !important; }
.color_dark2{ color: var(--color_dark2); } 

.color_deep_skyBlue{ color: var(--color_deep_skyBlue) !important; }

.color_purple_dark{ color: var(--color_purple_dark) !important; }


.pb_btm100{ padding-bottom: 100px; }
.pb_btm56{ padding-bottom: 56px; }
 
.width10{ width: 10%; }
.width20{ width: 20%; }
.width25{ width: 25%; }
.width30{ width: 30%; }
.width33{ width: 33.33%; }
.width40{ width: 40%; }
.width45{ width: 45%; }
.width70{ width: 70%; }
.width50{ width: 50%; }
.width60{ width: 60%; }
.width100{ width: 100% !important; }

.text_right{
   text-align: right;
}



.border-bottom{ border-bottom-color: var(--border_color2) !important;  }


.MuiPagination-ul{ justify-content: flex-end; padding: 8px 0px 0px 0px !important; }
.MuiPagination-ul li button{ border-color: #DFE3E8; font-size: 14px;  font-weight: 700;  line-height: 20px;}

.MuiPagination-ul li button.Mui-selected{ border-color: var(--color_blue_navy); color: var(--color_blue_navy); background: none;}

.MuiPagination-ul li button.Mui-selected:hover{ background: none;}

.MuiPagination-ul li button.Mui-disabled{ background: #C4CDD5 !important;; border-color: #C4CDD5 !important;}

 


.row{ margin: 0px -5px !important;}
.row>*{ padding-left: 5px !important; padding-right: 5px !important;} 


.t_datepicker{ border: 1px solid #EFEFEF !important; border-radius: 5px !important;  color: var(--color_black) !important; font-size: 12px !important;  font-weight: 500 !important; box-shadow: 0px 0px 0px transparent !important; outline: 0px none !important; padding-left: 30px !important; background: url(../assets/images/datepicker_icon.svg) no-repeat left 10px center !important;  }

.t_datepicker:focus{ border-color: var(--color_yellow) !important;}

 .t_datepicker ~ .button{ display: none !important;}

 .arrowColorText{ color: var(--color_green_pigment); font-size: 14px; font-style: normal; display: inline-block; vertical-align: top;  font-weight: 500; line-height: 1.4em; padding-left: 12px; background: url(../assets/images/arrow_down_green.svg) no-repeat left center; margin-left: 6px; background-size: 11px auto !important; }

 .arrowColorText.arrowUpRed{ color: var(--color_red); background: url(../assets/images/arrow_up_red.svg) no-repeat left center;}

 .sss_siteDetailNav{ width: 500px !important;}

.top4{ top: 4px !important;}

.sss_mtv_mapContent .mapchart_box{ height: 180px !important;}


.offcanvas-header .btn-close{ background-color: var(--border_color2) !important; border-radius: 50% !important; width: 42px !important; height: 42px !important; box-sizing: border-box !important; }

.borderNone{ border: 0px none !important;}

.inf_contentMain .mvc_performance{ justify-content: start; background: var(--color_white); }
.mvc_performance .mvc_card_detail_cell{ border-right: 1px solid #8C8C8C; }
 
.inf_heading span{ position: relative; z-index: 1; }
.heading_border_right::after{ content: ""; width: 100%; height: 1px; left: 0px; top: -11px; display: block; 
   position: relative; background: var(--color_gray2);}


.sp_totalFuel_heading strong{ line-height: 1em; vertical-align: middle; }
.sp_totalFuel_heading small{ line-height: 1em; }
 
.inf_summary_list tr:nth-child(odd) td, .inf_summary_list th {
  background-color: #D9D9D9;
}

.st_summary_list tr:nth-child(odd) td, .st_summary_list th{
   background-color: 0;
}


.inf_anomalies_content{ background: #7243D01A; border-radius: 10px; padding: 15px 6px 15px 10px; }

.inf_Osize_data ul li{ border-bottom: 2px solid var(--color_gray2); }
.inf_values_h{ color: var(--color_black); font-size: 25px; line-height: 1.2em;}


 
.infAnoC_data .pws_values ul li:before{ height: 38px; }
.infAnoC_data .pws_values ul li{ width: auto; }


.siteInst_status_icons i{ padding-right: 8px; }
.siteInst_status_icons i:last-child{ padding-right: 0px; }

.sitesInfra_content{ width: 100%; }

.sitesInfra_content_inner{ margin: 0 -8px; }
 
.comp_list ul li{ border-right: 1px solid var(--color_gray2); padding: 0px 50px;}
.sitesInfra_rSolarIns ul li{ padding: 0px 22px; }
.comp_list ul li:last-child{ border-right: 0px; padding-right: 0px; }
.comp_list ul li:first-child{ padding-left: 0px; }

.sp_comp_list_main .comp_list ul li{ padding: 0px 20px; }

.sp_totalFuel_content{ padding-top: 40px; }



 /* .smart-filter-group{ padding: 10px; background-color: green; border: 4px solid #fff;} */

   /* .smart-filter-group-condition{ padding: 10px; background-color: pink; border: 4px solid #fff; display: flex; }

   .filter-builder-item{ padding: 5px;} */


   .sites_solarPowerGeneration_chart{ position: relative;}
.chart_range_tabs{ position: absolute; left: 10px; top: 0px; z-index: 22;}
   .chart_range_tabs ul{ display: flex; flex-wrap: wrap; gap: 5px;}
   .chartRangeBtn{  width: 24px; height: 24px; display: block; text-align: center; line-height: 24px; background: #ECECEC; font-size: 12px; color:  var(--color_black); font-weight: 400; transition: all 0.3s ease; cursor: pointer; }

.chartRangeBtn:hover, .chartRangeBtn.active{ background-color: var(--color_black); color: var(--color_white);}



/* sob_search_out start here */

.sob_search_out{ margin-bottom: 65px; }

.sob_search_out .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root{
   background: url(../assets/images/search_icon.svg) no-repeat left 10px center;
   background-size: 30px auto;
   background-color: #fff;
   color: #000 !important;
   border-radius: 15.36px !important;
   font-family: 'Roboto', sans-serif !important; font-weight: 600 !important;
   padding:12px 65px 12px 50px !important;
}

.sob_search_out .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
   border: 0px !important;
}

.sob_search_out .css-qzbt6i-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator,
.sob_search_out .css-113ntv0-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator{ 
   display: none !important;
 }

 .sob_search_out .css-1glvl0p-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator{
   visibility: visible !important;
   border-radius: 10.24px !important;
   background: #000 !important;
   color: #FFC400 !important;
   font-family: 'Roboto', sans-serif !important; font-weight: 600 !important;
   font-size: 16px !important;
   padding: 5px 15px !important;
 }
 .sob_search_out .css-1glvl0p-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator::before{
   content: "Clear";
 }
 .sob_search_out .css-1glvl0p-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator svg{
   display: none !important;
 }

.sob_site_main{ padding-bottom: 65px; }

.sob_site_box{ background: #fff; border-radius: 10px; padding: 20px 30px; }

.sob_site_box_head{ padding-bottom: 20px; margin-bottom: 30px; border-bottom:1px solid #8C8C8C; }

.sob_site_box_head h4, .sob_site_box_text h4{ color: #000; font-size: 18px; line-height: 1.2rem; font-weight: 700; }
 

.sob_site_box_text span, .sob_site_box_text strong{ color: #000; font-size: 16px; line-height: 1.2rem; display: block; }
.sob_site_box_text strong { font-weight: 500; }

.sob_can_next_btn button{ max-width: 122px; width: 100%; text-align: center; border-radius: 3px !important; }

.sobSrch_main_parent{ padding-top:5%;}

.sobSteps_wrapper{ position: relative;}

.sobSteps_content{ height: calc(100vh - 190px); overflow: hidden; overflow-y: auto; -webkit-overflow-scrolling: touch; }

.sobSaveModel{ box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.15); }

.sobSaveModel .modal-body{ padding: 16px 20px; }

.sobSaveModel .modal-dialog{ width: 100%; max-width: 490px; }

.sobSaveModel_close{ display: flex; align-items: center; justify-content: flex-end;} 

.sobSaveModel_close svg{ padding: 10px; border-radius: 50%; background-color: #F4F4F4; } 

.sobSaveModel_tick svg{ max-width: 100px; width: 100%; display: block; }

.sobSaveModel_content h3{ font-size: 28px; font-weight: 600; line-height: 1.2em; padding: 20px 0px; text-align: center; color: #000; }

.sobSaveModel_table .sob_site_box_head{ color: #000; font-size: 16px; font-weight: 700; line-height: 1.2em; text-align: left !important;}

.sobSaveModel_done{ display: flex; align-items: center; justify-content: center; width: 100%; padding-top: 28px; }



.tnp_perAnalys_row{ width: 100%;}

.tnp_perAnalys_box{ width: 100%; border-radius: 10px;  background: #FFF; box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.15); }

.tnp_perAnalys_top{ width: 100%; padding: 15px; }


.tnp_perAnalys_top h3{ color: #000; font-size: 20px;  font-weight: 500; line-height: 1.0em; padding-bottom: 20px; }
.tnp_perAnalys_top h3 small{ font-size: 62%;}


.nar_chartBoxes_row{ width: 80%; margin: auto; position: relative; margin-bottom: 36px; }

.nar_chartBoxes_row ul{ display: flex; align-items: center; justify-content: space-around; position: relative;  }

.nar_chartBoxes_row ul:before{  content: ''; width: 100%; height: 1px; display: block; position: absolute; left: 0px;
bottom: 15px; background: #000;  }

.nar_chartBoxes_row li{ width:100%; padding: 0px 20px; position: relative; }

.nar_chartBoxes_row li:after, .nar_chartBoxes_row li:first-child:before{ content: ''; width: 8px; height: 42px; background: url(../assets/images/arrow_down_long.svg) no-repeat bottom center; background-size: 100% auto; display: block; position: absolute; right: -4px; top:100%; margin-top: -18px; }

.nar_chartBoxes_row li:first-child:before{ right: initial; left: -4px; }


.nar_chartBox{ display: flex; align-items: center; justify-content: center; flex-wrap: wrap; border: 0.574px solid #000; background: #FFF; min-height: 32px; padding: 5px; max-width: 134px; margin: auto; }

.nar_chartBox > div{ padding-left: 2px; margin-left: 2px; border-left: 1px solid #D1D1D1; color: #000; font-size: 12px;  font-weight: 600; line-height: 1.2em; display: flex; align-items: center; gap: 4px;  }

.nar_chartBox > div:first-child{ border-left: 0px none; padding-left: 0px; margin-left: 0px; }

.nar_chartBox > div.nar_chartVal_percent{ color: #B50000; }



.tnp_analys_compares{ width: 100%;}

.tnp_analys_compares.activeRed .tnp_analys_content{ border-color: var(--color_red2); background-color: rgba(221, 0, 0, 0.07); }

.tnp_analys_header{ width: 100%; }

.tnp_analys_header ul li{ width: 100%;}


.tnp_analys_headerBox{  box-shadow: 5px -1px 3px rgba(0, 0, 0, 0.16); border-radius: 20px 20px 0px 0px; color: var(--color_white); padding: 8px 12px; }

.tnp_analys_headerBox strong{ display: block; padding-bottom: 5px; font-size: 16px; font-weight: 500; }

.tnp_analys_headerBox h3{ display: flex; align-items: center; color: #FFF;  font-size: 22px; font-weight: 500; line-height: 1.2em; }


.tnp_analys_headerBox h3 span{ display: flex; width: 50%;  }

.tnp_analys_headerBox h3 span:not(:first-child)::before{ content: ""; width: 1px; height: 20px; border-right:1px solid var(--color_white); margin-right: auto; display: block; margin-top: 4px; }


.tnp_analys_content{ width: 100%; padding:20px 15px 15px 15px; background-color: rgba(23, 167, 0, 0.07); border:3px solid var(--color_green3); border-radius:0px 0px 10px 10px;  }





.tnp_analys_compareChart{ max-width: 320px; margin: auto; padding-bottom: 15px; }

.tnp_np_header .ampButton{ min-width: 80px; display: block; } 
 
.tnp_narpar_3boxes_chart{ width: 100%;  width: 228px; margin: auto; }

.tnp_narpar_3boxe_row{ width: 100%; display: flex; }
.tnp_narpar_3boxe_col1{ width: calc(100% - 226px);}
.tnp_narpar_3boxe_col2{ width: 226px; flex-shrink: 0; }


.exe_summary_header.tnp_np_header{ position: sticky;  top: 58px; z-index: 33;  background: #fff; }

 

 








