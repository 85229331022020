
.header_main.welcome_header{ background-color: #131325;position: relative; z-index: 22; }

.welcome_header .headerMain_title span{ color: #fff;}
.welcome_header .headerMain_title:before{ background-color: #fff;}

.wc_user_info strong{ display: block; color: #FFF; font-size: 22px; font-weight: 700; padding-right: 30px;  background: url(../../assets/images/welcome_logout.svg) no-repeat right center; background-size: 20px auto; }
.wc_user_info small{ display: block; color: #9090A6; font-size: 14px;  font-weight: 400;   }


.welcome_main{ width: 100%; min-height: 100vh; background-color: #3F3F57  !important; background: url(../../assets/images/welcome_bg.svg) no-repeat right bottom; background: linear-gradient(180deg, #131325 0%, #3F3F57 100%); overflow: hidden;
}

.welcome_main:after{ width: 76%;
  height: calc(100vh + 60%);
  position: fixed;
  right: -2%;
  top: -60%; content: ""; background: url(../../assets/images/welcome_bg2.svg) no-repeat center center; background-size: cover; }
.welcome_content{ width: 100%; display: flex; }
.welcome_content{ width: 100%; display: flex; }
 


.welcome_link{ height: 100%; padding: 12px 16px;  background: #3C3C56; display: flex;  align-items: center; border-radius: 10px; gap: 10px; background: linear-gradient(180deg, rgba(19, 19, 37,0.5) 0%, rgba(63, 63, 87,0.5) 100%); min-height: 60px; background: rgba(0, 0, 0, 0.7); }

.welcome_link span{ background:linear-gradient(25deg, #FF7F02 0%, #FFC400 100%); background-clip: text; -webkit-background-clip: text; -webkit-text-fill-color: transparent; font-size: 14px;  font-weight: 700; display: block;}

.welcome_link i{ display: block; width: 20px; height: 24px; flex-shrink: 0; margin-top: -1px; }

.welcome_link i svg{ display: block; max-width: 20px;}

.welcome_list li a.active, .welcome_list li a:hover{ 
   /* background: rgba(0, 0, 0, 0.9); */
   background: linear-gradient(25deg, #FF7F02 0%, #FFC400 100%);
   box-shadow: 0px 2px 26px rgba(255, 196, 0, 0.27);
   color: #000;
  
  }

  .welcome_list li a.active span, .welcome_list li a:hover span{ background:none; -webkit-text-fill-color: #000;    color: #000; font-weight: 700;} 
  .welcome_list li a.active svg path, .welcome_list li a:hover svg path{ stroke: #000 !important;}


.wc_left_list li{ padding-bottom: 15px;}

 
.wc_nav_link{ height: 100%; padding: 15px 20px; background: #3C3C56; display: block;  align-items: center; border-radius: 10px; padding-left: 60px; position: relative; box-shadow: 0px 2.0567216873168945px 16.453773498535156px rgba(0, 0, 0, 0.15);  color: #FFF; }


.wc_nav_link i{border-radius: 10.284px; background: linear-gradient(25deg, #FF7F02 0%, #FFC400 100%);
box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.15);  display: block; width: 36px; height: 36px; display: flex; align-items: center; justify-content: center; position: absolute; left: 15px; top: 15px; }

.wc_nav_link i svg{ display: block; max-width: 22px; max-height: 22px;}

.wc_nav_link strong{ display: block;  font-size: 20px; font-weight:700;  line-height: 1.3em; padding-bottom: 10px; padding-top: 3px; }

.wc_nav_link span{ display: block;  font-size: 14px; font-weight:500;  line-height: 1.4em;}

.wc_left_list li a.active, .wc_left_list li a:hover{ background: linear-gradient(25deg, #FF7F02 0%, #FFC400 100%); box-shadow: 0px 2px 26px rgba(255, 196, 0, 0.27);  color: #000; }


.wc_left_list li.disabled{ pointer-events: none;}
.wc_left_list li.disabled .wc_nav_link{ background-color: #3C3C56; color: #5E5E79; }
.wc_left_list li.disabled .wc_nav_link i{ background: #5E5E79; }
.wc_left_list li.disabled .wc_nav_link i svg{ opacity: 0.5;}

.welcome_title_border{ border-bottom: 5px solid #707070; margin-bottom: 16px;  padding-bottom: 16px !important;}



.welcome_has_top_icon .welcome_link{ flex-direction: column; text-align: center;}

.welcome_wrap{ width: 100%; padding:15px;  position: relative; z-index: 3;  }

.welcome_left_nav{ flex-shrink: 0; width: 400px; border-radius: 10px; padding: 10px; background: linear-gradient(180deg, rgba(19, 19, 37, 1) 0%, rgba(63, 63, 87, 1) 100%);     height: calc(100vh - 106px); overflow-y: auto; overflow-x: hidden; }





.wc_search_field{ width: 100%; margin-bottom: 15px; position: relative; }

.floating_label{ font-size: 14px; line-height: 16px; font-weight: 700; color: #90989F;
   position: absolute; top: 18px; left: 40px;
   pointer-events:none;
   transition:0.175s ease all; 
   -moz-transition:0.175s ease all; 
   -webkit-transition:0.175s ease all; }

.wc_search_field input{ width: 100%; height: 50px; padding: 20px 80px 6px 40px;  
  border:2px solid #F4F4F4; border-radius: 10px; font-size: 16px; line-height: 20px;
   color: #1A1D1F; transition:0.175s ease all;  -moz-transition:0.175s ease all; 
   -webkit-transition:0.175s ease all; background: url(../../assets/images/search_icon.svg) no-repeat left 10px center !important; background-size: 18px auto; background-color: #F4F4F4 !important; }

.wc_search_field input.check_mark, .wc_search_field input:focus.check_mark{ background-image: url(../../assets/images/input_check.svg);  background-size: 16px auto; }

.wc_search_field input:focus{ background: #43454F; border-color: #FFC400; }

.wc_search_field input:focus ~ .floating_label{ top: 6px; }
.wc_search_field input:not(:placeholder-shown) ~ .floating_label { top: 6px; }



.welcome_right{ width: 100%; padding-left: 20px; min-height: calc(100vh - 106px); display: flex; flex-direction: column; justify-content: space-between;}

.wc_time_header{ width: 100%; display: flex; justify-content: space-between; }

.wc_time_header_left{ padding-right: 15px;}

.wc_time_header_left h2{ color: #FFF; font-size: 30px; font-weight: 700; padding-bottom: 10px; }

.wc_time_header_left ul{ display: block; max-width: 160px; text-align: center; }

.wc_time_header_left ul li{ font-size: 16px; position: relative; padding-bottom: 32px; margin-bottom: 12px; font-weight: 500; text-transform: uppercase; color:#FFC400;  background:  linear-gradient(25deg, #FF7F02 0%, #FFC400 100%); background-clip: text; -webkit-background-clip: text; -webkit-text-fill-color: transparent; }

.wc_time_header_left ul li:before{ content: ""; width: 30px; height: 24px; background: url(../../assets/images/arrow-thick-dot-bottom.svg) no-repeat center center; background-size: 70% auto; display: block; position: absolute; left: 50%; bottom: 0px; margin-left: -15px;  }

.wc_time_header_left ul li:last-child{ padding-bottom: 0px; margin-bottom: 0px;}
.wc_time_header_left ul li:last-child::before{ display: none;}



.wc_time_title{ color: #FFF; text-align: right; font-size: 14px; font-weight: 500; display: block; padding-bottom: 10px; line-height: 1.3em; }

.wc_time_srch_command{ font-size: 14px; color: #1A1D1F; font-weight: 600; line-height: 24px;  border-radius: 8px; background: #FFF; box-shadow: 0px 2px 0px 0px rgba(255, 255, 255, 0.25) inset, 0px -1px 1px 0px rgba(0, 0, 0, 0.04) inset, 0px -1px 2px 0px rgba(0, 0, 0, 0.05) inset; position: absolute; right: 8px; top: 12px; padding: 5px 10px 3px 10px; display: inline-block; line-height:1.3em; }


.wc_quick_links{ width: 100%; border-radius: 10px; background: rgba(0, 0, 0, 0.6); padding: 14px 14px 14px 24px; position: relative; }

.wc_quick_links:before{ content: ""; background: linear-gradient(25deg, #FF7F02 0%, #FFC400 100%); width: 10px; height: 100%; position: absolute; left: 0px; top: 0px; border-radius:10px 0px 0px 10px; }

.wc_quick_links h3{ font-size: 22px; font-weight: 700; line-height: 1.2em; padding-bottom: 12px; color:#FFC400;  background:  linear-gradient(25deg, #FF7F02 0%, #FFC400 100%); background-clip: text; -webkit-background-clip: text; -webkit-text-fill-color: transparent;  }

.welcome_list{  width: 100%; display: flex; gap: 10px; flex-wrap: wrap; }
.welcome_list li{ width: calc(25% - 10px);}



.welcome_list span{  font-size: 16px; font-weight:500;  line-height: 1.3em;}





/* width */
.welcome_main ::-webkit-scrollbar {
    width: 8px; border-radius: 3px;
  }
  
  /* Track */
  .welcome_main  ::-webkit-scrollbar-track {
    background: #3C3C56; border-radius: 3px;
  }
  
  /* Handle */
  .welcome_main ::-webkit-scrollbar-thumb {
    background: #131325; border-radius: 3px;
  }





 
.onboarding_ops_wrap.welcome_main:after{ background: url(../../assets/images/onboarding_main_bg.png) no-repeat top right !important; background-size: auto  100% !important; width: 100% !important; height: 100vh; top: 0px; left: 0px; }


.sob_welcome_btn_main{ min-height: calc(100vh - 96px); display: flex; align-items: center; justify-content: center; }

.sob_welcome_btn_list{ display: flex; justify-content: center; }

.sob_welcome_btn{ width: 336px; height: 352px; border-radius: 10.284px; background: #3C3C56; box-shadow: 0px 2.05672px 16.45377px 0px rgba(0, 0, 0, 0.15); display: flex; align-items: flex-end; justify-content: center; margin: 10px 35px; padding: 35px; text-align: center; transition:0.175s ease all; -moz-transition:0.175s ease all; -webkit-transition:0.175s ease all; }

.sob_welcome_btn span{ display: block; color: #FFF; font-size: 24px; line-height: 1.2rem; font-weight: 700;
  -moz-transition:0.175s ease all; 
  -webkit-transition:0.175s ease all;  transition:0.175s ease all;  }

.sob_welcome_btn:hover{ background: #5E5E79; }

.sob_welcome_btn.disabled{ pointer-events: none; }
.sob_welcome_btn.disabled svg path{ fill:#5E5E79; stroke: #131325; }
.sob_welcome_btn.disabled span{ color: #5E5E79; }

.sob_welcome_btn figure{ display: flex; justify-content: center; margin-bottom: 55px; }




 
.onboarding_srch_wrap.welcome_main:after{ background: url(../../assets/images/onboardingSrch_bg.png) no-repeat top center !important; background-size: auto  100% !important; width: 100% !important; height: 100vh; top: 0px; left: 0px; }






