.sob_topTabs_main{ width: 100%;}
.sob_topTabs_row{ width: 100%;}

.sob_topTabs_ul{ width: 100%; display: flex; gap: 36px; padding-bottom: 6px;}

.sob_topTabs_li{ width: auto; position: relative; opacity: 0.5; pointer-events: none; }

.sob_topTabs_li::after{ content: '';  display: block; width: 28px;  height: 1px; border-bottom: 1.38px dashed #94A3B8; position: absolute;  top: 50%; right: -32px; margin-top: -1px; }

.sob_topTabs_li:last-child::after{ border: none; }

.sob_topTabs_box{ width: 100%; min-height: 50px; border-bottom: 3px solid #fff; color: #131325;  border-radius: 2px; padding:6px 10px 4px 10px; display: flex; justify-content: space-between; align-items: center; cursor: pointer; transition: all 0.175s ease; background-color: #fff; position: relative; z-index: 1; }

.sobInfo_box .css-1p8u6xs-MuiFormControl-root{ width: 100%; margin: 0; }

.sobInfo_box .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{ width: 100%; }

.sobInfo_box .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{ text-align: left; }
.sobInfo_box .css-14lo706{ width: 0; }

.sobInfo_box .applyFilter_box{ padding: 0px; }
 
.sobInfo_box .applyFilter_box > .MuiFormControl-root{ width: 100%;}

.sobInfo_box .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root{ display: none; }

.sob_topTabs_box p{ width: 100%; font-weight: 500; font-size: 12px; line-height: 1.4em; transition: all 0.175s ease; text-transform: uppercase; }


.sob_topTabs_li.previous-active .sob_topTabs_box span{ border-color: #FFC400; }
.sob_topTabs_li.previous-active:after{ border-bottom-style: solid; }


.sob_topTabs_li.active .sob_topTabs_box{ background-color: #000000 !important; border-bottom-color: #FFC400; color: #fff; }

.sob_topTabs_li:hover .sob_topTabs_box{ background-color: #ececec; border-color: #ececec;}

.sob_topTabs_li.previous-active, .sob_topTabs_li.active{ opacity: 1; pointer-events: initial; }
 

.sob_topTabs_li.active svg, .sob_topTabs_li.active svg path{   stroke: #fff !important;}


.tvg_toptab_more .btn-primary{ background: none !important; padding: 0px !important; border: 0px none !important; display: block; width: 100%; text-align: left;}

.tvg_toptab_more .btn-primary:after{ display: none;}

.tvg_toptab_more .sob_topTabs_li{ width: 100%; background: none !important; padding: 0px !important; border: 0px none !important; display: block; width: 100%; text-align: left;}


.tvg_toptab_more .dropdown-menu{ min-width: 300px; border-radius: 0px;  border: 1px solid #8C8C8C; }

.tvg_toptab_more .dropdown-menu .sob_topTabs_box{ background-color: #fff; border-radius: 0px; border-bottom: 1px solid #8C8C8C; }


.tvg_toptab_more .sob_topTabs_li:last-child .sob_topTabs_box{ border-bottom: 0px none !important;}

.tvg_toptab_more .sob_topTabs_li:hover .sob_topTabs_box{ background-color: #ececec; }

.tvg_price_border{ padding-right:4px; }
.tvg_price_border h3::after{ content: ""; height: 14px; position:absolute; right: -5px; top: 5px; border-right: 1px solid #6F767E; }




.table_filter_progress ul li::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    border: 1px dashed #94A3B8;
    position: absolute;
    top: 10px;
    left: 0px;
}



 



/* Sob style */
.sobTopTabs_value{ padding-right: 6px; }

.sobTopTabs_value span{ padding: 6px;  border-radius: 50px; font-size: 12px; font-weight: 600; line-height: 18px; text-align: center; width: 30px; height: 30px; display: block; border: 1.4px solid #CBD5E1; }

.sob_topTabs_li.active .sobTopTabs_value span{ background-color: #FFC400; border-color: #fff; color: #000; }




 