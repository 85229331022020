 .am_top_size {
     overflow: hidden;
 }

 .am_top_size li {
     border-left: 1px solid #D1D1D1;
     min-width: 16.66%;
 }

 .am_top_size li:first-child {
     border-left: 0px none;
 }

 .am_top_size_box {
     position: relative;
     text-align: center;
     padding: 12px 12px 12px 12px;
 }

 .am_top_size_box h4 {
     color: #000;

     font-size: 16px;

     font-weight: 500;
     padding-bottom: 6px;
 }

 .am_top_size_box h3 {
     color: var(--neutral-06, #272B30);
     text-align: center;

     font-size: 18px;

     font-weight: 700;
 }

 .am_top_size_box small {
     color: var(--neutral-04, #6F767E);
     text-align: center;

     font-size: 12px;

     font-weight: 500;
     text-transform: uppercase;
 }

 .am_color_bar {
     position: absolute;
     width: 100%;
     height: 6px;
     display: block;
     left: 0;
     bottom: 0;
     flex-shrink: 0;
 }

 .faulty_transformers {
     border-right: 1px solid #8C8C8C;

 }

 .faulty_transformers:last-child {
     border-right: none;
 }


 .am_faulty_transformers {
     text-align: center;
     display: block;
     font-weight: 500;
     padding-bottom: 8px;
     line-height: 1.2em;
 }

 .am_faulty_transformers span {
     font-weight: 500;
     padding-top: 4px;
     padding-bottom: 10px;
     font-size: 14px;
     line-height: 1.2em;
     color: #000000;
     display: block;
 }

 .faulty_transformers h2 {
     color: #111315;
     font-size: 32px;
     font-weight: 500;
     line-height: 1.2em;
 }

 .feeders_status_row {
     display: flex;
     align-items: center;
     justify-content: center;
 }

 .feeders_status_cell {
     padding: 0px 10px;
     position: relative;
     flex-shrink: 1;
     flex-grow: 1;
     text-align: center;
 }

 .feeders_status_cell:before {
     content: "";
     width: 1px;
     height: 70%;
     position: absolute;
     left: 0px;
     top: 15%;
     display: block;
     background-color: #EFEFEF;
 }

 .feeders_status_cell:first-child:before {
     display: none;
 }

 .feeders_status_cell h5 {
     color: #111315;
     text-align: center;
     font-size: 18px;
     font-weight: 500;
     display: block;
 }

 .feeders_status_cell small {
     font-size: 12px;
     font-weight: 500;
     display: block;
 }





 .am_campaign_box {
     border: 1px solid rgba(140, 140, 140, 0.25);
     border-radius: 6px;
     padding: 8px 12px 8px 18px;
     display: flex;
     align-items: center;
     justify-content: space-between;
     position: relative;
     overflow: hidden;
     min-height: 66px;
 }

 .am_vertical_bar {
     display: block;
     width: 6px;
     height: 100%;
     background-color: #5B525E;
     position: absolute;
     left: 0px;
     top: 0px;
 }

 .am_campaign_cell h3 {
     color: #000;
     font-size: 16px;
     font-weight: 500;
     text-transform: uppercase;
     line-height: 1.2em;
 }

 .am_campaign_cell small {
     display: block;
     color: #6F767E;
     font-size: 10px;
     font-weight: 500;
     line-height: 1.2em;
     padding-top: 2px;
 }

 .am_campaign_cell strong {
     color: #000;
     font-size: 20px;
     font-weight: 500;
     text-transform: uppercase;
     line-height: 1.2em;
 }

 .am_donut_chart_main {
     position: relative;
 }

 .am_donut_chart_main .ampButtonsGroup {
     position: absolute;
     left: 0px;
     top: 0px;
     z-index: 2;
 }

 .am_transformer_map_h .mapchart_box {
     height: 596px !important;
 }

 .am_battery_map .mapchart_box {
     height: 475px !important;
 }

 .am_gen_map .mapchart_box {
     height: 422px !important;
 } 
 .am_top_size {
     border-radius: 5px;
     overflow: hidden;
 }

 .raised_text {
     padding-left: 20px;
     position: relative;
     display: inline-block;
 }

 .raised_circle {
     width: 16px;
     height: 16px;
     border-radius: 50%;
     background-color: #01A340;
     position: absolute;
     left: 0px;
     top: 10px;
 }

 .am_box_smallHeight {
     min-height: 50px;
 }

 .am_backup_st {
     width: 100%;
     display: flex;
     flex-wrap: wrap;
     padding-top: 10px;
     padding-bottom: 5px;
 }

 .am_backup_st_left {
     width: 50%;
     padding-right: 10px;
     display: flex;
     flex-wrap: wrap;
 }

 .am_backup_st_badges_main {
     width: 50%;
 }

 .am_backup_st_badges_main ul {
     width: 100%;
     position: relative;
     border-radius: 16px;
     border: 8px solid #D9D9D9;
     padding: 5px;
 }

 .am_backup_st_badges_main ul::before {
     content: "";
     width: 34px;
     height: 16px;
     position: absolute;
     top: -28px;
     left: 50%;
     transform: translate(-50%);
     border-radius: 16px;
     background-color: #D9D9D9;
 }

 .am_backup_st_badges_main ul li {
     padding-bottom: 4px;
 }

 .am_backup_st_badges_main ul li:last-child {
     padding-bottom: 0px;
 }

 .am_backup_st_badges {
     width: 100%;
     height: 24px;
     border-radius: 30px;
 }

 .am_backup_st_hrs {
     width: 50%;
     padding: 14px 0px 0px 10px;
 }

 .am_backup_st_hrs ul li {
     padding-bottom: 9px;
     font-size: 14px;
     line-height: 1.2rem;
     font-weight: 500;
 }

 .am_backup_st_right {
     width: 50%;
     padding-left: 10px;
 }

 .am_backup_st_right ul {
     display: flex;
     flex-wrap: wrap;
     margin: 0px -5px;
     padding-top: 20px;
 }

 .am_backup_st_right ul li {
     width: 50%;
     padding: 0px 5px 10px;
 }

 .am_backup_st_sites {
     width: 100%;
     padding: 8px 10px 8px 35px;
     border-radius: 6px;
     background: #EFEFEF;
     position: relative;
 }

 .am_backup_st_sites span {
     font-size: 16px;
     line-height: 1.2rem;
     font-weight: 600;
     color: #000;
 }

 .am_backup_st_sites span em {
     font-size: 11px;
     line-height: 1.2rem;
     font-weight: 500;
     color: #000;
     padding-left: 3px;
 }

 .am_legend_dot {
     content: "";
     width: 24px;
     height: 12px;
     position: absolute;
     left: 5px;
     top: 50%;
     transform: translate(0, -56%);
     border-radius: 3px;
 } 
 .raised_circle_small .raised_circle {
     top: 4px;
 }

 .amDbAlldownloadInfo {
     float: right !important;
     padding: 0px !important;
     justify-content: normal !important;
 }

 .amDbAlldownloadInfo_btn {
     padding: 4px 8px;
     transition: all 0.3s ease;
     cursor: pointer;
     background-color: #fff;
     display: block;
 }

 .amDbAlldownloadInfo_btn:hover {
     background: var(--color_black);
 }

 .myAllInfoBox {
     border: 1px solid #000;
     padding: 5px 10px;
     justify-content: space-between;
     align-items: center;
     max-width: 270px;
     margin-left: auto;
 }

 .amDbAlldownloadInfo_btn:hover i svg path,
 .amDbAlldownloadInfo_btn:hover i svg circle { transition: all 0.3s ease;}

 .amDbAlldownloadInfo_btn:hover i svg path,
 .amDbAlldownloadInfo_btn:hover i svg circle {
     stroke: #FFAD00 !important;
 } 
 .monthYearBtn {
     padding: 3px 5px;
     border-radius: 5px;
     font-weight: 500;
     font-size: 14px;
     line-height: 1.2em;
     cursor: pointer;
     transition: all 0.3s ease;
 }

 .monthYearBtn:hover {
     background: #000;
     color: #fff;
 }

 .monthYearBtn.active {
     background: #000;
     color: #fff;
     font-weight: 500;
 } 

 /* table filter */
 .table_filter_main {
     width: 100%;
     padding-top: 20px;
 }

 .table_filter_auto {
     max-width: 1500px;
     margin: auto;
     padding: 0px 240px;
 }
.table_filter_inner{
    position: relative;
}
 .table_filter {
     width: 100%;
     padding: 20px 30px;
  
 }

 .tfFiltersHeight{   height: calc(100vh - 250px);
    min-height: 200px;
   overflow: hidden;
   overflow-y: auto;
  -webkit-overflow-scrolling: touch; }

 .tf_inner {
     position: relative;
 }

 .table_filter_progress {
    padding: 0px 10px 28px 10px;
 }

 .table_filter_progress ul {
     display: flex;
     align-items: center;
 } 
 .table_filter_progress ul li {
     position: relative;
     padding-right: 80px;
 }
 
 .table_filter_progress ul li::after {
     content: '';
     display: block;
     width: 100%;
     height: 1px;
     border: 1px dashed #94A3B8;
     position: absolute;
     top: 10px;
     left: 0px;
 }

 .table_filter_progress ul li.active::before {
     content: '';
     display: block;
     width: 100%;
     height: 5px;
     background: #FF8301;
     position: absolute;
     top: 8px;
     left: 0px;
     z-index: 1;
 }

 .tf_progress {
     position: relative;
     z-index: 1;
     background: var(--color_white);
     padding: 0px 10px;
     display: flex;
     align-items: center;
 }

 .tf_progress span {
     border: 1px solid #CBD5E1; 
     color: #000;
     font-size: 12px;
     font-weight: 600;
     line-height: 22px;
     border-radius: 50%;
     text-align: center;
     width: 24px;
     height: 24px;
     display: block;
 } 
 .tf_progress strong {
     font-size: 16px;
     font-weight: 600;
     line-height: 1.2em;
     color: #94A3B8;
     padding-left: 10px !important;
 }

 .table_filter_progress ul li.active .tf_progress span {
     border: 1px solid var(--color_orange3);
 }

 .table_filter_progress ul li.active .tf_progress strong {
     color: #000;
 } 

 .tf_heading {
     padding-bottom: 20px;
 }

 .tf_table::after {
     content: "";
     width: 2px;
     height: calc(100% - 28px);
     background: #EEE;
     position: absolute;
     display: block;
     left: 20px;
     top: 28px;
 }

 .tf_table {
     padding-left: 46px;
 }

 .tf_table:first-child .smart-condition-operator,  .smart-filter-group-row:first-child .smart-filter-group-operator{
 display: none !important;
 }

 .smart-filter-add-condition-btn{
    margin-left: 46px;
 }

 .tf_row_inner {
     display: flex;
     align-items: center;
     border: 1px solid #EEE;
     border-radius: 2px;
     padding: 5px 15px;
     max-width: 656px;
 }

 .tf_row_inner .filter-builder-item{
    padding: 0px 20px;
 }
 

 .filter-builder-item .applyFilter_box {
     padding: 6px 20px;
     background: #F2F2F2;
     border-radius: 2px; 
 }

 .applyFilter_box input{
    max-width: 106px;
 }


 .filter-builder-item .applyFilter_box select {
     padding-right: 36px;
     font-size: 16px;
     color: #33383F;
     line-height: 1.2em;
 }

 .tf_menu_icon {
     cursor: grabbing;
 }
 
 .tf_dropdownBtn .dropdown button {
     background: #F7F7F7;
     font-size: 14px;
     font-weight: 500;
     line-height: 1.2em;
     color: #000;
     padding: 8px 20px;
     display: block;
     cursor: pointer;
     border: 1px solid #F7F7F7;
 }

 .tf_dropdownBtn .am_cp_btn{
    background: var(--color_black);
    font-size: 14px;
    font-weight: 500;
    line-height: 1.2em;
    color: var(--color_white);
    padding: 8px 20px;
    display: block;
    cursor: pointer;
    border: 1px solid #F7F7F7;
 }

 .smart-filter-group-operator .am_cp_btn{
    position: relative;
    z-index: 2;
    margin-bottom: 14px;
 }

 .tf_dropdownBtn .dropdown button::after {
     border: 0;
 }

 .tf_plus_dropdownBtn .dropdown {
     padding-left: 6px;
 }

 .tf_plus_dropdownBtn .dropdown button {
     font-size: 20px;
     padding: 6px;
 }

 .tf_plus_dropdownBtn{
    padding: 8px 12px;
    background-color: var(--color_grey_light);
    z-index: 2;
    position: relative;
 }

 .tf_addOr_btn{
    padding: 8px 20px;
    position: relative;
    z-index: 2;
 }

 .tf_dropdownBtn .dropdown button:first-child:active {
     color: var(--color_white) !important;
     background: var(--color_black) !important;
     border-color: var(--color_black) !important;
 }

 .tf_dropdownBtn .dropdown .dropdown-menu.show {
     min-width: 66px;
     box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.07);
     border: 0;
 }
 

 /* pagination */

 .am_pagination {
     display: flex;
     justify-content: space-between;
     align-items: center;
     padding: 8px 0px 10px 0px;
 }

 .am_pagination_icon {
     display: flex;
     align-items: center;
 }

 .am_pagination_icon i {
     padding: 8px;
     background: #F7F7F7;
     margin-right: 8px;
     cursor: pointer;
 }

 .am_pagination_icon i:hover {
     background-color: #ececec;
     border-color: #ececec;
 }

 .am_pagination_right {
     display: flex;
 }

 .am_pagination_rowPg .applyFilter_box {
     border: 1px solid #DFE3E8;
     border-radius: 4px;
     margin-right: 30px;
 }

 .am_pagination_rowPg strong {
     flex-shrink: 0;
 }

 .tf_next_reset_btn{
    width: auto;
    position: absolute;
    bottom: 0;
    left: 100%;
    display: flex;
    padding-left: 20px;
 }

 .tf_next_reset_btn button {
     padding: 8px 28px;
     border-radius: 2px;
 }
 

 .tf_select_columns {
     padding: 10px 30px; min-height: 100%;
 }

 .tf_select_heading {
     padding: 20px 10px;
 }

 .tf_select_checkbox .form-check {
     padding: 14px 10px 20px 30px;
     border-bottom: 1px solid #F2F2F2;
 }

 .tf_select_checkbox .form-check input {
     width: 18px;
     height: 18px;
     margin-right: 40px;
 } 
 .tf_select_checkbox .form-check.active {
     background: var(--color_grey_dark2);
 } 
 .tf_select_checkbox .form-check.active small {
     font-weight: 600;
 } 
 .tf_select_checkbox .form-check-input:checked {
     background-color: var(--color_black);
     border-color: #64748B;
 } 
 
 .tf_select_checkbox .form-check-input:focus {
     box-shadow: none;
     border-color: #64748B;
 }

 .tf_selected {
     border-radius: 10px;
     padding: 10px;     min-height: 100%;
 } 
 .if_selected_list {
     display: flex;
     align-items: center;
     justify-content: space-between;
     padding-bottom: 20px;
 } 
 .if_selected_list i {
     padding: 6px;
     background-color: var(--color_black);
     border-radius: 5px;
     cursor: pointer;
     display: block; 
 }
 
 .if_selected_dltBtn{
    flex-shrink: 0;
    width: 24px;
    cursor: pointer;
 }

 .ep_tOutput_table td {
     border-bottom: 0 !important;
 } 
 .ep_tOutput_table tr:nth-child(odd) td {
     background-color: #F1F1F1;
 } 
 .ep_tOutput_chart_heading {
     display: flex;
     justify-content: space-between;
     align-items: center;
 } 
 .ep_tOutput_chart_heading .tvg_topTabs_price {
     background: var(--color_blue_navy);
     padding: 3px 8px;
 } 
 .amDbTopHeading {
     background: #F7F7F7;
 } 
 .amDbTopHeading h4 {
     padding: 14px 10px;
 } 
 .amDb_ts_content {
     width: 100%;
     overflow-y: auto;
    max-height: calc(100vh - 180px);
 }  
 

  .offcanvas-body{ overflow: hidden;}

  .amDb_ts_content ul li.active{ 
    background-color: var(--color_grey_dark2) !important;
  }

  .tf_selectBox ul li.active{
    background-color: var(--color_grey_dark2) !important;
  }
 
 .amDb_ts_content_row {
     display: flex;
     padding-left: 10px;
     align-items: center;
     flex-wrap: wrap;
     width: 100%;
 } 
 .amDb_ts_content_row i {
     min-width: 45px;
     width: 24px;
     height: 24px;
     background-image: url('../../assets/images/drag_icon.svg');
     display: block;
     background-repeat: no-repeat; 
     background-position: left;
 } 
 .amDb_ts_content_row i{
     cursor: move;
 }  
 
 .amDb_ts_checkbox {  
     width: calc(100% - 45px);
 } 

 .amDb_ts_checkbox .form-check-label{
    padding: 18px 10px 18px 0px;
    width: 100%;
    cursor: pointer;
 }
 .amDb_ts_checkbox input {
     margin-right: 30px;
 } 
 .amDb_ts_checkbox .form-check-input:checked {
     background-color: var(--color_black);
     border-color: #64748B;
 } 
 .amDb_ts_checkbox .form-check-input:focus {
     box-shadow: none;
     border-color: #64748B;
 }

 .amDb_ts_footer{
    padding: 24px 40px 8px 40px;
 }
 .amDb_ts_footer .mvc_visit_btn{
    display: flex;
    align-items: center;
 }
 .amDb_ts_footer .mvc_visit_btn .applyFilter_button{
    padding: 10px 18px;
 }

 .amDb_ts_footer .applyFilter_button:hover{
    color: var(--color_white) !important;
 }
  

 .am_pg_tHeading_row{
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
 }

 .am_pg_tHeading{
    width: 132px;
    padding: 0px 4px;
 }
.am_pg_tHeading_content{
    width: calc(100% - 132px);
    display: flex;
    align-items: center;
}
 .am_pg_tContent{
    display: flex;
    align-items: center; 
    position: relative; 
    padding-left: 26px; 
    /* display: inline-block; */
    flex-wrap: wrap;
 }

 .am_pg_tSideHeading{
    font-size: 1rem;  
    position: absolute;
    top: 50%;
    left: 0;
    margin-left: -26px;
    transform: rotate(270deg);
 }

 .am_pg_tContent_row{
    display: flex;
    align-items: center;
    width: 100%;
 }

 .am_pg_tRow_box{
    text-align: center;
    padding: 1px;
    width: 16.66%;
 }
  
 .am_pg_tRow_box_inner{
    padding: 10px 10px;  
 }

 .am_pg_tRow_data{
    display: flex;
    align-items: center;
    width: calc(100% - 106px);
 }

 .am_pg_tC_row_h{
    width: 106px;
    padding: 6px;
 }

 .am_pg_bottom_text{
    padding: 8px 5px;
 }

 .am_playground_map{
    background-color: #FAFAFA;
    border-radius: 10px;
    padding: 10px;
 }
.amPlaygroundChartBox .mapchart_box {
    height: 460px !important;
}
 .am_pg_tHeading_sOption{
    padding-right: 14px;
 }
 
 .am_pg_tHeading_sOption .MuiFormControl-root{
    min-width: 204px;
    margin: 0px !important;
 }
 
 .amDb_table_hAuto .MuiTableContainer-root{
    height: auto !important;
 }
 .stf_targetsOutpBattery thead{
    background-color: var(--color_blue_navy) !important;
 }
 
 .stf_targetsOutpG  thead{
    background-color: var(--color_blue_slate) !important;
 }
 .stf_targetsOutpSolar thead{
    background-color: var(--color_yellow) !important;
 }

 .stf_targetsOutpGenset thead{
    background-color: var(--color_green_sea) !important;
 }

 .stf_targetsOutpFuel thead{
    background-color: var(--color_orange) !important;
 }
 

 /* width */
.amDb_tableSetting ::-webkit-scrollbar {
    width: 6px;  height: 6px; border-radius: 3px; 
  }
  
  /* Track */
  .amDb_tableSetting  ::-webkit-scrollbar-track {
    background: #FCFCFC; border-radius: 3px;
  }
  
  /* Handle */
  .amDb_tableSetting ::-webkit-scrollbar-thumb {
    background: #8C8C8C; border-radius: 3px;
  }

  .am_tableFilter_top_btns{ padding: 0px 20px; }
  .am_tableFilter_top_btns .btn{ padding: 9px 15px; }

  .stf_table_height .MuiTableContainer-root{ height: auto !important; }


/* sites targets minimize section */
.st_db_heading{ display: flex; align-items: center; justify-content: space-between; }
 .st_db_heading_cells{ display: flex; align-items: center; justify-content: center; }

 .st_db_chart, .st_db_heading{ border-bottom: 1px solid var(--color_gray2); padding-bottom: 14px; }

 .st_db_content_data{ display: flex; align-items: center; justify-content: center; padding: 10px;
     border-bottom: 1px solid var(--color_gray2); }
 .st_db_data_icon{ display: flex; align-items: center; }

 .st_db_view .btn{ padding: 5px 10px; border-radius: 20px !important; }

 .st_db_view{ text-align: end; }

 .st_db_content_data .feeders_status_cell:before{ background-color: var(--color_gray2) !important; }

 
/* Sites targest styling */
.sitesTarget_content{ width: 100%; }

.sitesTarget_content .mvc_performance{ background: var(--color_white) !important; justify-content: flex-start; }

.sl_kw{ display: flex; align-items: center; padding: 3px 14px; background-color: var(--color_red2); width: 60%; border-radius: 10px; }
.sl_kw span{ padding-right: 14px; }

.st_sl_kw_btns{ display: flex; align-items: center; width: 100%;}

.st_sl_kw_btns .label_btn{ padding: 15px 34px; border-radius: 10px; margin-right: 16px; text-align: center;  width: 40%;}

 .border_bottom{ border-bottom: 1px solid var(--border_color); }
.st_summary_list th, .st_summary_list td{ border-bottom: 0px; }
.st_summary_list th{ padding-bottom: 12px; }

.st_battery_cells{ padding: 8px 0px 0px 0px; } 

.st_battery_cells ul{ display: flex; align-items: center; flex-wrap: wrap; margin: 0px -5px; }
.st_battery_cells ul li{ width: 20%; padding: 0px 5px; }

.st_bCells_inner small{ border-radius: 18px 18px 0px 0px; text-transform: uppercase; padding: 5px; display: block; text-align: center; }

.st_battery_cells .box_card{ padding: 0px; border-radius: 18px; }

.st_bCells_inner{ min-height: 70px; }

.st_bCells_inner i{ min-height: 50px; display: flex; align-items: center; justify-content: center; padding: 8px; }
.st_bCells_inner i svg{ max-width: 24px; max-height: 24px; margin: auto; } 

.st_disable_cell{ opacity: 0.5;  background: #EFEFEF; border-radius: 0px 0px 18px 18px; }

.st_tInputs_heading{ padding: 18px; border-radius: 10px 10px 0px 0px ; }

.st_tInputs_inner{ padding: 8px 15px; }

.st_tInputs_optins{ display: flex; align-items: center; margin: 0px -5px; flex-wrap: wrap;}

.st_tInputs_optins .st_tInputs_optins_cells{ width: 14.28%; }

.st_tInputs_optins_cells .MuiFormControl-root{ min-width: 100%; }
.st_tInputs_optins .am_pg_tHeading_sOption{ padding: 10px; }

.st_tInputs_optins .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{ padding: 14px; }
.st_tInputs_optins .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon{ right: 4px; }

.st_tInputs_optins .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root { top: -3px; }

 .st_calcTargets_inner{ padding: 8px 15px; }


.st_calcTargets_chart .echarts-for-react{ height: 278px; }


.outerCricleHeading{ display: flex; align-items: center; justify-content: space-between; text-align: left; }



.ssView_chart_heading{ padding-bottom: 10px; }
.ssView_cchartH_border{ border-bottom: 1px solid #b5bac3; }

.dashB_content_heading{ padding-bottom: 10px; }



.site_reachability .ssView_circleChart_cell{ width: 190px;  padding: 12px 24px; height: auto; margin: auto; }
.site_reachability .sp_circleChart{ display: flex;  height: 100%; justify-content: space-between;  align-items: center;
    flex-direction: column; padding: 0; }

.ssView_circleChart_cell .CircularProgressbar-path {
    stroke: #d5b5d6;
  }
  .CircularProgressbar-trail {
    stroke: #e6ebf8;
  }
  .CircularProgressbar-text {
    fill: yellow;
  }
  .CircularProgressbar-background {
    fill: green;
  }
 

  .ssView_circleChart{ width: 100%; display: flex; align-items: center; justify-content: space-between; flex-wrap: wrap;
     padding: 81px 0px;  }
  .ssView_circleChart_cell{ width: 120px; height: 120px; }


  .sp_circleChart{ padding: 20px 0px; }
 
 .sp_circleChart .CircularProgressbar-path{ stroke: #D5B5D6; } 
  
 .sp_circleChart .strokeClr_green .CircularProgressbar-path{ stroke: #32864B; }
 .sp_circleChart .strokeClr_blue .CircularProgressbar-path{ stroke: #0059FF; }

 .sp_circleChart .strokeClr_pink .CircularProgressbar-path{ stroke: #E8B5A8; }
 .sp_circleChart .strokeClr_lBlue .CircularProgressbar-path{ stroke: #ADC1DA; }

 .insi_left_heading{ padding: 20px 30px; background-color: #21AB4A; text-align: center;}

 .insi_left_heading h2{ font-size: 26px; }

 .insi_solar_power .comp_list{ border-bottom: 1px solid var(--color_gray2); padding: 24px 0px; }

 .insi_solar_power h2 span, .extCloudy_heading h2 span{ font-size: 50px; }

 .insiSolar_data_main{ display: flex; align-items: center; flex-wrap: wrap; justify-content: center; padding: 20px 0px; }

 .insiSolar_data_main .insiSolar_data{ width: 33.33%; border-right: 1px solid var(--color_gray2); }

 .insiSolar_data_main .insiSolar_data:last-child{ border-right: 0; }

 .insiSolar_dataTop h2{ padding: 20px 0px; }

 .insiSolar_dataTop h2 span{ font-size: 50px; }


 .insiSolar_cloudyData{ padding: 58px 15px 30px 15px; }

 .extCloudy_heading{ padding: 10px 0px 30px 0px; }

 .extCloudy_heading small{ padding-bottom: 24px; }

 .insi_solar_heading{ display: flex; align-items: center; justify-content: space-between; }


 /* Site On Boarding style start */
 .sobGeneralInfo_inner{ padding: 20px 20px; }

 .sob_heading h2{ color: var(--color_dark2); padding-bottom: 20px; font-size: 17px; font-weight: 600; line-height: 1.2em;  }

 .sobInfo_box h4{ color: var(--color_dark3); padding-bottom: 3px; font-size: 12px; font-weight: 400; line-height: 1.2em;}
 .sobInfo_box h4 span{ color: var(--color_red4); padding-left: 3px; font-size: 12px; font-weight: 400; line-height: 1.2em; }


 

 .sobInfo_input{ width: 100%; padding: 10px; border: 1px solid var(--color_gray_light4); border-radius: 5px; }

 .sobInfo_input.applyFilter_box select{ width: 100%; text-align: left; }

 .sobInfo_input textarea{  color: var(--color_dark3); width: 100%; }

 .sob_summary_data th, .sob_summary_data td{ background-color: inherit; }

 .sob_summary_data th, .sob_summary_data td{ border-bottom: 1px solid var(--border_color3); padding-bottom: 16px; font-size: 16px; font-weight: 400; }
 
 .sob_summary_data td{ padding-top: 16px; }

 .sobGeneralInfo .tf_next_reset_btn{ position: relative; left: 0; justify-content: end; }
 .sobGeneralInfo .tf_next_reset_btn button:hover{ background-color: #000; color: #fff; border-color: #fff; }
  


.tmp_totalSitesRow{ display: flex; padding-left: 20px; align-items: center; }
 .tmp_totalSitesBox { display: flex; flex-shrink: 0; align-items: center; padding: 12px 16px; background-color: #FFF5D5; border-bottom: 1px solid #EBEBEB; }

 .tmp_totalSitesBox strong{ font-size: 16px; font-weight: 600; line-height: 1.2em; color: var(--color_black); padding-right: 10px; }

 .tmp_totalSitesBox h3{ font-size: 28px; font-weight: 600; line-height: 1.2em; color: var(--color_black);  }
 .tmp_totalSitesFilters{ width: 100%; }

.tnpSummary .psu_headlines_row{ margin-bottom: 0; border-bottom: 0; }

.tnpChartValue{ padding: 6px; border: 1px solid #000; margin-bottom: 6px; }

.tnpChartValue ul{ display: flex; align-items: center; justify-content: center; } 
.tnpChartValue ul li{ padding: 0px 7px; position: relative;}
.tnpChartValue ul li::before{ content: ""; width: 1px; height: 70%; position: absolute; left: 0px;  top: 15%; display: block; background-color: #000; } 

.tnpChartValue ul li:first-child:before{ display: none; }

.TnpPerChartValue{ display: flex; align-items: center; }

.tnpChartValue span{ color: #000; font-size: 18px; font-weight: 600; line-height: 1.2em; }
.tnpChartValue span sub{ font-size: 9px; }

.TnpPerChartValue strong{ color: #00B53D; font-size: 18px; line-height: 1.2em; font-weight: 600; }
.TnpPerChartValue strong sub{ font-size: 9px; }


.tnpSiteBox .faulty_transformers{ padding: 36px 0px; }

.tnpSiteBox .am_faulty_transformers span{ font-size: 18px; }
.tnpSiteBox .am_faulty_transformers h2{ font-size: 40px; font-weight: 900; }

.tnpSiteBox .feeders_status_row small{ font-size: 16px; line-height: 1.2em; color: #000; }
.tnpSiteBox .feeders_status_row h4{ font-size: 28px; line-height: 1.2em; font-weight: 500; }

.tnpSiteBox .feeders_status_cell:before{ background-color: var(--color_gray2); }

.TnpEnergyConsMapChart .mapchart_box{ height: 753px !important; }

/* .tnpGrid .faulty_transformers{ padding: 16px 0px; } */

.tnpSiteBox{ border-bottom: 1px solid #D1D1D1; }

.grid_siteBoxChart{ padding: 16px 0px; }

.tnpPerformanceCompInner{ position: relative; }

.tnpPerformanceCompInner::before{ content: "";  width: 66%;  height: 100%;  position: absolute;  display: block; right: 0px; top: 0px; }

.tnpGrid .tnpPerformanceCompInner::before{ background-color: #E8F6EE; }
.tnpOpex .tnpPerformanceCompInner::before{ background-color: #E8EFED; }
.tnpSolar .tnpPerformanceCompInner::before{ background-color: #FDF6E3; }

.tnpPar .tnpPerformanceCompInner::before{ background-color: #F6ECF6; }
.tnpFuelConsumption .tnpPerformanceCompInner::before{ background-color: #E8F6EE; }
.tnpNar .tnpPerformanceCompInner::before{ background-color: #F4EDEB; }
.tnpTch .tnpPerformanceCompInner::before{ background-color: #E0E5ED; }

.tnpEnergyConsumption .tnpPerformanceCompInner::before{ background-color: #FBE4E0; }

.tnpCo2PerComp .tnpPerformanceCompInner::before{ background-color: #EBE9E9; }

.tnpPerformanceCompInner{  padding: 6px 0px; background-color: #F8F8F8; }

.tnpPerformanceCompInner .nar_chartBoxes_row, .tnpPerformanceCompInner .nar_chartTop{ position: relative; z-index: 2; }

.tnpNarParChartHeading{ display: flex; align-items: center; justify-content: space-between; padding-bottom: 10px; }
.tnpNarParChartHeading h3{ padding-bottom: 0px; line-height: 0; }

.tnpNarParChartHeading .amp_btn_margin .ampButtonsGroup{ margin-bottom: 0; }

.feaders_value_Box{ padding-top: 16px; }
.feaders_value_Box ul{ display: flex; align-items: center; justify-content: center; }
.feaders_value_Box ul li{ width: 33.33%; padding: 0px 10px; border-right: 2px solid #DDD; }
.feaders_value_Box ul li:last-child{ border-right: 0; }

.feaders_value_Box_inner span{ display: block; color: #000; font-size: 12px; line-height: 1.2em; font-weight: 500; }
.feaders_value_Box_inner h4{font-size: 16px !important; line-height: 1.2em; font-weight: 500; }

.narParSites.feeders_status_cell:before{ height: 100%; top: 0; width: 2px; }

.tnpNarParMapChart .mapchart_box{ height: 766px !important; }

.tnpEnergyCons .tnpSiteBox{ border-bottom: 0; }





/* data stories css */

.data_stories_main .sob_welcome_btn{ align-items: center; }

.data_stories_main .sob_welcome_btn span{ line-height: 1.8rem; }

.data_stories_main .sob_welcome_btn:hover{ background: var(--Linear, linear-gradient(46deg, #FF7F02 15.25%,
    #FFC400 72.33%)); box-shadow: 0px 2.057px 16.454px 0px rgba(0, 0, 0, 0.15); }

.data_stories_main .sob_welcome_btn:hover span{ color: #131325; font-weight: 700; }   

.data_stories_main .sob_welcome_btn:hover svg path{ stroke: #131325; fill: #131325; }

.dsComSavingLinks_main{ width: 100%; padding-top: 50px; z-index: 1; position: relative; }

.comSavingLinksBox{ padding: 24px; background: #3C3C56; border-radius: 10px; box-shadow: 0px 2.057px 16.454px 0px
    rgba(0, 0, 0, 0.15); height: 100%;}

.comSavingContent h4{ color: #fff; font-size: 24px; line-height: 1.2em; font-weight: 500; padding-bottom: 10px; }
.comSavingContent p{ color: #fff; font-size: 12px; font-weight: 500; line-height: 1.2em; padding-bottom: 20px; }
.comSavingContentBtn{ padding: 10px 20px; color: #000; font-size: 12px; font-weight: 500; background: var(--Linear, linear-gradient(46deg, #FF7F02 15.25%, 
    #FFC400 72.33%)); box-shadow: 0px 2.057px 16.454px 0px rgba(0, 0, 0, 0.15);}


.cs_gp_content_right{ background: #f8f8f8; padding: 20px; }

.ds_peakHrs_info h2{ font-size: 28px; font-weight: 700; line-height: 1.2em; color: #000; padding-bottom: 30px; }
.ds_peakHrs_info_list{ padding-left: 20px; }
.ds_peakHrs_info_list ol li{ font-size: 20px; font-weight: 400; line-height: 1.2em; color: #000; padding-bottom: 10px;
    list-style: auto !important; }

    .ds_peakHrs_chrt{ margin-top: -70px;}
.ds_peakHrs_chrt img{ position: relative; width: 100%; display: block; height: auto;  max-width: 90%; }

.gridPeakHrs_main{ width: 100%; }

.cs_gp_heading{ display: flex; align-items: center; justify-content: space-between; }
.cs_gp_heading h2{ color: #000; font-size: 26px; line-height: 1.2em; font-weight: 700; }
.cs_gp_hrs_inner{ padding-top: 30px; }
.cs_gp_hrs_content{ padding-top: 30px; }

.cs_gp_hrs_content_inner{ padding: 20px 0px; }


.cs_gp_hrs_statusRow{ display: flex; align-items: flex-start; justify-content: flex-end; }
.cs_gp_hrs_status_cell{ padding: 0px 15px; text-align: center; position: relative; }

.cs_gp_hrs_status_cell::after { content: "";  width: 1px;  height: 54%; position: absolute; left: 0px; top: 40%;
    display: block; background-color: var(--color_purple_dark); }
.cs_gp_hrs_status_cell:first-child::after{ display: none; } 

.cs_gp_hrs_status_text small{ font-size: 17px; font-weight: 500; line-height: 1.2em; color: #000; }

.cs_gp_hrs_status_text h2{ font-size: 40px; line-height: 1.2em; font-weight: 900; color: #000; }
.cs_gp_hrs_status_text h2 strong{ font-weight: 900; }

.cs_gp_hrs_status_text h2 sub{ font-size: 24px; font-weight: 900; position: relative; top: -1px; }


.cs_gp_hrs_statusRow_below{ display: flex; align-items: center; }
.cs_gp_hrs_status_belowCell{ position: relative; padding: 0px 10px; }
.cs_gp_hrs_status_belowCell::after{content: ""; width: 1px; height: 80%;  position: absolute; left: 0px; top: 22%;
    display: block;  background-color: #DDD; }
.cs_gp_hrs_status_belowCell:first-child:after{ display: none; }

.cs_gp_hrs_status_text_below small{ font-size: 10px; font-weight: 500; color: #000; line-height: 1.2em; }

.cs_gp_hrs_status_text_below h2{ font-size: 16px; font-weight: 700; line-height: 1.2em; }

.cs_gp_hrs_sRow_btn_grp{ display: flex; align-items: center; padding-top: 30px; justify-content: flex-end; } 
.cs_sRow_btn{ padding: 10px; font-size: 13px; font-weight: 500; line-height: 1.2em; background: #A472B4;
    color: #fff; margin-right: 14px ; }

.cs_gp_hrs_sRow_btn_grp .myAllInfoBox{ margin: 0; }






.cs_gp_hrs_content_info h4{ color: #000; font-size: 22px; font-weight: 700; line-height: 1.2em; 
    text-transform: capitalize; padding-bottom: 10px; max-width: 664px; } 

.cs_gp_hrs_content_info p{ font-size: 18px; font-weight: 400; line-height: 1.2em; color: #000; max-width: 654px; }
.cs_gp_hrs_content_info p strong{ font-weight: 700; }

.cs_gp_content_info{ display: flex; flex-direction: column; justify-content: space-between;   height: 100%; padding-bottom: 20px; }
.cs_gp_content_info h2{ padding-bottom: 46px; font-size: 36px; font-weight: 700; color: #000; line-height: 1.2em; }
.cs_gp_info_data{ text-align: center; }
.cs_gp_info_data span{ display: block; color: #000; font-size: 20px; font-weight: 500; line-height: 1.2em;}
.cs_gp_info_data strong{ color: #01A340; font-size: 48px; font-weight: 900; line-height: 1.2em; }
.cs_gp_info_data sub{ color: #01A340; font-size: 18px; font-weight: 900; position: relative;  top: -1px; }
.border_right{ border-right: 1px solid #DDD; }
.cs_gp_info_list{ border-bottom: 1px solid #DDD; padding: 20px 0px; }

.cs_gp_amButtons{ display: flex; align-items: center; }


.ds_solarPotentialMax .tnpPerformanceCompInner::before {  background-color: #E8EFED; }

.ds_solarPotentialMax .tnpPerformanceCompInner::before{ width: 50%; }

.ds_solarPotentialMax .nar_chartBox{ max-width: 220px; } 
.ds_sp_chartBox{ display: block !important; }

.ds_sp_chartBox_list{ display: flex; align-items: center; gap: 8px; padding-bottom: 10px; }
.ds_sp_chartBox_list:last-child{ padding-bottom: 0px; }

.nar_chartVal_percent{ display: flex; align-items: center; gap: 4px; }

.ds_sp_chartDayHrs, .ds_sp_chartBox_list .nar_chartVal_type{ font-size: 18px; line-height: 1.2em; font-weight: 600; 
    color: #000; }

.ds_sp_chartBox_list .nar_chartVal_percent{ font-size: 18px; font-weight: 600; color: #01A340; line-height: 1.2em; 
    border-left: 1px solid #D1D1D1; padding-left: 8px;}

.ds_solarPotentialMax .nar_chartBoxes_row li:after, .ds_solarPotentialMax .nar_chartBoxes_row li:first-child:before{ margin-top: -51px !important; }
.ds_solarPotentialMax .nar_chartBoxes_row ul:before{ bottom: 50%; }

.ds_solarPotentialMax .nar_chartBoxes_row{ margin-bottom: 24px; }

.ds_sp_chartBox_list .nar_chartVal_arrow svg path{ font-size: 12px; stroke: #01A340; fill: #01A340 !important; font-weight: 600; }

 



.cs_pagination{ width: 100%; padding-top: 10px; width: 100%; 
    position: sticky;
    bottom: 0;
    background: rgba(255,255,255, 0.95); z-index: 22; }

.cs_pagination ul{ display: flex; align-items: center; justify-content: space-between; }
.cs_pagination .cs_arrow{ display: block;}
.cs_pagination .cs_arrow svg{ width: 80px; height: auto; display: block; cursor: pointer; }

.cs_pagination_dots{ display: flex; align-items: center; justify-content: space-between; }

.cs_pagination_dots a{ border: 1px solid #D9D9D9; padding: 10px; border-radius: 50%; background: #D9D9D9; margin: 0px 15px; cursor: pointer;}

.cs_pagination_dots a.active{ background: #131325; }

 .cp_gp_policy{ padding-bottom: 10px; }


 /* Network Assets Discovery css */

 .networkAssetsDiscovery{ width: 100%; padding-top: 46px; }

 .nsd_data_collection{ text-align: center; padding: 26px 0px; }
 .nsd_data_collection span{ color: var(--color_black); font-size: 20px; line-height: 1.2em; padding-bottom: 20px; display: block; }

 .nsd_data_collection h4{ font-size: 60px; font-weight: 900; line-height: 1.2em; }

 .networkAssetsDiscovery .stf_tableWrap .MuiTableContainer-root{ height: calc(100vh - 468px); min-height: 300px; }

 /* Site-Assets Discovery css */

 .siteAssetsDiscovery{ width: 100%; padding-top: 40px; }

 .siteAssetsDiscovery .stf_tableWrap .MuiTableContainer-root{ height: calc(100vh - 458px);  min-height: 300px; }
 
 .sad_details_li{ width: 20%; } 

 .sad_detail_box{ text-align: center; }

 .sad_detail_heading{ width: 100%; display: flex; justify-content: space-between; align-items: center; padding-bottom: 10px; margin-bottom: 10px;  border-bottom: 1px solid var(--border_color); } 

 .sad_detail_heading h4{ font-size: 16px; line-height: 1.2em; color: var(--color_black); font-weight: 500; padding-top: 4px; }

 .sad_detail_content{ height: 100px; display: flex; flex-direction: column; align-items: center;  justify-content: center; }

 .sad_detail_content span{ color: var(--color_black); font-size: 16px; font-weight: 500; line-height: 1.2em; }

 .sad_detail_box p{ color: var(--color_black3); font-size: 12px; font-weight: 500; line-height: 1.2em; padding-top: 14px; }


/* network puls */

.networkPuls{ width: 100%; padding-top: 20px; }

.networkPuls .ampButtonsGroup{ padding-bottom: 20px; } 

/* npTables */

.npTables{ width: 100%; }
.npTables_inner{ width: 100%; }

.npTableHeading{ color: var(--color_black); font-size: 14px; font-weight: 700; line-height: 1em; padding-bottom: 6px;}
 
.npPingSnmpDiffTable .stf_tableWrap .MuiTableContainer-root{ height: calc(100vh - 322px);  min-height: 300px; }

.npSiteDownIpUnreachable .stf_tableWrap .MuiTableContainer-root{ height: calc(100vh - 322px);  min-height: 300px; }
.npSnmpDown .stf_tableWrap .MuiTableContainer-root{ height: calc(100vh - 322px);  min-height: 300px; }

.projectWiseGrouping .stf_tableWrap .MuiTableContainer-root{ height: calc(100vh - 322px);  min-height: 300px; }







